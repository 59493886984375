import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../../App'
import LoadingPage from '../../LoadingPage'

export const RedirectToLogin = () => {
  const auth0 = useContext(AuthContext)
  const { loginWithRedirect } = auth0

  useEffect(() => {
    loginWithRedirect({ screen_hint: 'signup' })
  }, [])

  return <LoadingPage fullHeight={true} />
}
