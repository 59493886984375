export const callUntilAvailable = (
  callback,
  id,
  delay,
  repetitions,
) => {
  let x = 0
  callback(id)
  const intervalID = window.setInterval(function () {
    callback(id, intervalID)
    if (++x === repetitions) {
      window.clearInterval(intervalID)
    }
  }, delay)
}

export const poolApi = (
  callback,
  params,
  delay,
  repetitions,
) => {
  let x = 0

  const intervalID = window.setInterval(function () {
    callback(params, intervalID)
    if (++x === repetitions) {
      window.clearInterval(intervalID)
    }
  }, delay)
}

export const poolApiXTimes = (
  callback,
  params,
  delay,
  repetitions,
) => {
  let x = 0

  const intervalID = window.setInterval(function () {
    callback(params, 0)
    if (++x === repetitions) {
      window.clearInterval(intervalID)
    }
  }, delay)
}

export const parseQuery = (queryString) => {
  let query
  query = {}
  let pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&')

  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export const getKey = () => {
  return Math.floor(Math.random() * 1000000000)
}

export const copyStringToClipboard = (str) => {
  // Create new element
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = str
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style.left = '-9999px'
  el.style.position = 'absolute'
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export const stripPath = (path) => {
  try {
    return path.split('/').pop()
  } catch (e) {
    return path
  }
}

export const timeSince = (date, abbreviated = false) => {
  // @ts-ignore
  let seconds = Math.floor((new Date() - date) / 1000)

  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + (abbreviated ? ' y' : ' years')
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + (abbreviated ? ' mon' : ' months')
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + (abbreviated ? ' d' : ' days')
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + (abbreviated ? ' h' : ' hours')
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + (abbreviated ? ' min' : ' minutes')
  }
  return Math.floor(seconds) + ' seconds'
}
/* eslint-disable */
export const sanitizeFileName = (fileName: string) =>
  fileName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
/* eslint-enable */

export const getIdByBlockIndex = ({ type, lineNumber, index }) => {
  return `${type}_${lineNumber}_${index}`
}
