import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const Tooltip = ({ point, unit, yIsPercentage }) => {
  const { data, borderColor } = point
  let xLabel = ''
  if (unit === 'minutes') {
    // this gives us the fractional component in seconds,
    // E.g. if data.x = 6.50, the .50 corresponds to 30 seconds
    const seconds = Math.floor((data.x % 1) * 60)
    const minutes = Math.floor(data.x)
    xLabel = `${minutes}min ${seconds}sec`
  } else if (unit === 'hours') {
    // this gives us the fractional component in minutes,
    // E.g. if data.x = 6.50, the .50 corresponds to 30 minutes
    const minutes = Math.floor((data.x % 1) * 60)
    const hours = Math.floor(data.x)
    xLabel = `${hours}h ${minutes}min`
  } else if (unit === 'seconds') {
    xLabel = `${Math.floor(data.x)} sec`
  } else {
    xLabel = `${Math.floor(data.x)} ${unit}`
  }

  return (
    <div
      className="border-gray-700 shadow-sm rounded-sm py-1 px-1 bg-white"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <span
        className="border-gray-500 inline-block"
        style={{
          borderColor: borderColor,
          background: borderColor,
          height: '10px',
          width: '10px',
          marginRight: '10px',
        }}
      ></span>
      <span className="ml-1">
        Time: <strong>{`${xLabel}`}</strong>
      </span>
      <span className="ml-4">
        {' '}
        Coverage: <strong>{`${data.y}${yIsPercentage ? '%' : ''}`}</strong>{' '}
      </span>
    </div>
  )
}

export const CoverageChart = ({
  data,
  unit,
  maxY = 100,
  empty = false,
  height = '22rem',
  seriesLabel = 'Coverage',
  yAxisLabel = `% of Instructions Covered`,
  yIsPercentage = true,
}: any) => {
  const config = [
    {
      id: seriesLabel,
      color: 'hsl(210, 62%, 57%)',
      data,
    },
  ]

  if(data.length === 0) {
    return <p className='ml-2 my-4 text-sm font-medium text-gray-500 text-center'>No data display</p>
  }

  return (
    <div style={{ height }}>
      {/**@ts-ignore */}
      <ResponsiveLine
        data={config}
        tooltip={({ point }) => (
          <Tooltip point={point} unit={unit} yIsPercentage={yIsPercentage} />
        )}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        xScale={empty ? { type: 'linear', min: 0, max: 1 } : { type: 'linear' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: maxY,
          stacked: true,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `Time (${unit})`,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisLabel,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={{ scheme: 'paired' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="coverage"
        pointLabelYOffset={-12}
        enableArea={true}
        enablePoints={false}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 10,
            translateY: 46,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}
