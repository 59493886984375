export const Header = ({ email = 'no email configured' }) => {
  return (
    <header className="bg-gray-800 pb-32 pt-10 mb-10">
      {/*<!-- This example requires Tailwind CSS v2.0+ -->*/}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <img
          className="h-16 w-16 rounded-full"
          src={
            'https://s.gravatar.com/avatar/63cf7a87cf0316884abbc6c27dd2b35d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fko.png'
          }
          alt=""
        />
        <div className="flex-1 min-w-0">
          <h2 className="ml-4 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
            {email}
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4"></div>
      </div>
    </header>
  )
}
