import { ENVIRONMENT } from './services';
import { init as initApm } from '@elastic/apm-rum'

let apmEnv = ENVIRONMENT.client === "production" ? 'prod' : ENVIRONMENT.client

var apm = initApm({
 // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
 serviceName: 'dili-faas-frontend',
 // Set the version of your application
 // Used on the APM Server to find the right sourcemap
 serviceVersion: '0.90',
 // Set custom APM Server URL (default: http://localhost:8200)
 serverUrl: 'https://3ba2bc8b5d93416f966cdbcd72290c58.apm.eastus.azure.elastic-cloud.com:443',
 environment: apmEnv
 // distributedTracingOrigins: ['http://localhost:8080'],
})
export default apm;