import { AuthContext, GettingStartedContext } from '../App'
import {
  ChevronDownIcon,
  LoginIcon,
} from '@heroicons/react/solid'
import { Disclosure, Menu, Transition } from '@headlessui/react'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  featuresEnabled,
  getConsumption,
  getSubscriptions,
} from '../services'

import UsageBanner from './UsageBanner'
import logo from '../FuzzingLogo_White.svg'
import { parseJwt } from '../utils'

const LOCAL_STORAGE_JWT_NAME = 'faas-jwt'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navbar = () => {
  const auth0 = useContext(AuthContext)
  const { setOpen } = useContext(GettingStartedContext)

  let { pathname } = useLocation()
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = auth0
  const [picture, setPicture] = useState()
  const [email, setEmail] = useState()

  const navigation = [
    { name: 'Dashboard', href: '/', target: '/', local: true },
    { name: 'API Keys', href: '/keys', target: '/keys', local: true },
    {
      name: 'Getting Started',
      onClick: () => {
        setOpen(true)
      },
    },
    {
      name: 'Docs',
      href: 'https://fuzzing-docs.diligence.tools/',
      target: null,
      local: false,
    },
  ]

  let defaultUserNavigation = [
    {
      name: 'Profile',
      href: '/profile',
      type: 'internal-link',
    },
    {
      name: 'Subscription',
      href: '/subscription',
      type: 'internal-link',
    },
    {
      name: 'API Keys',
      href: '/keys',
      type: 'internal-link',
    },
    {
      name: 'Sign out',
      type: 'button',
      onClick: logout,
    },
  ]

  if (!featuresEnabled.stripeSubscriptions) {
    defaultUserNavigation = defaultUserNavigation.filter(
      (i) => i.name !== 'Subscription'
    )
  }

  const [userNavigation, setUserNavigation] = useState(defaultUserNavigation)
  const [limitReached, setLimitReached] = useState(false)
  // we store the user's subscription
  const [subscription, setSubscription] = useState(undefined)

  const handleLogout = () => {
    if (window) {
      localStorage.removeItem(LOCAL_STORAGE_JWT_NAME)
      localStorage.removeItem('refusedCheckout')
    }
    logout({ returnTo: "https://consensys.net/diligence/fuzzing" })
  }

  const handleFetchData = async () => {
    try {
      if (!user) {
        return
      }

      const accessToken = await getAccessTokenSilently()

      if (window) {
        window.localStorage.setItem(LOCAL_STORAGE_JWT_NAME, accessToken)
      }
      const { email, picture } = user
      // window.user = user
      setPicture(picture)
      setEmail(email)

      const { permissions } = parseJwt(accessToken)
      const isAdmin = permissions && permissions.indexOf('admin') > -1

      if (isAdmin) {
        setUserNavigation([
          {
            name: 'Admin Panel',
            href: '/admin',
            type: 'internal-link',
          },
          ...userNavigation,
        ])
      }
      const { limitReached, fuzzingLimit } = await getConsumption(auth0)
      // doing limitReached && fuzzingLimit to discard users without any subscription plans
      // or without usage limits configured, which is the case for new accounts. In these cases
      // it doesn't make sense to show the banner.
      setLimitReached(limitReached && fuzzingLimit)

      const { activeSubscription } = await getSubscriptions(auth0)
      setSubscription(activeSubscription)
    } catch (e) {
      console.error('Error getting user data. ', e)
    }
  }

  useEffect(() => {
    handleFetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  // here we depend only on `user` because the Auth0 client will update one in it's state at every refresh of a page
  // or at tokens (access or refresh) expiration

  return (
    <>
      {limitReached ? <UsageBanner /> : undefined}
      <HarveyBenchmarkBanner />
      <Disclosure as="nav" className="bg-gray-800 pt-4">
        {({ open, close }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="h-16 w-44"
                        src={logo}
                        alt="diligence-faas"
                      />
                    </Link>
                  </div>
                  <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                    {navigation.map((item) => {
                      if (item.onClick) {
                        return (
                          <span
                            key={item.name}
                            onClick={item.onClick}
                            className={
                              'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer'
                            }
                          >
                            {item.name}
                          </span>
                        )
                      }
                      const current = item.target === pathname
                      if (item.local) {
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        )
                      } else {
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            rel="noreferrer"
                            className={classNames(
                              current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium cursor-pointer'
                            )}
                            aria-current={current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        )
                      }
                    })}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {isAuthenticated ? undefined : (
                      <button
                        onClick={() =>
                          loginWithRedirect({ screen_hint: 'signup' })
                        }
                        id="login-button"
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                      >
                        <LoginIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span>Login</span>
                      </button>
                    )}
                  </div>
                  {email ? (
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-400">
                        {email}
                      </div>
                    </div>
                  ) : undefined}
                  <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                    {/* Profile dropdown */}
                    {isAuthenticated && picture && email ? (
                      <Menu as="div" className="ml-3 relative z-10">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={picture}
                                  alt=""
                                />
                                {/* <DotsVerticalIcon className='self-center flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500' /> */}
                                {/* <MenuIcon className='ml-2 self-center flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500' /> */}
                                <ChevronDownIcon className="ml-2 self-center flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {userNavigation.map((item) => {
                                  if (item.type === 'button') {
                                    return (
                                      <Menu.Item key={item.name}>
                                        {({ active }) => (
                                          <span
                                            role="button"
                                            onClick={item.onClick}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                            )}
                                          >
                                            {item.name}
                                          </span>
                                        )}
                                      </Menu.Item>
                                    )
                                  }

                                  if (item.type === 'internal-link') {
                                    return (
                                      <Menu.Item key={item.name}>
                                        {({ active }) => (
                                          <Link
                                            to={item.href}
                                            role="button"
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                            )}
                                          >
                                            {item.name}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    )
                                  }

                                  else {
                                    return <></>
                                  }
                                })}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    ) : undefined}
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => {
                  const current = item.target === pathname
                  if (item.local) {
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    )
                  } else {
                    return (
                      <a
                        key={item.name}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(
                          current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    )
                  }
                })}
              </div>

              {isAuthenticated && picture && email ? (
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5 sm:px-6">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={picture}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-400">
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1 sm:px-3">
                    <Link
                      to="/profile"
                      onClick={close}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      Profile
                    </Link>
                  </div>
                  <div className="mt-3 px-2 space-y-1 sm:px-3">
                    <Link
                      to='/subscription'
                      onClick={close}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      Subscription
                    </Link>
                  </div>
                  <div className="mt-3 px-2 space-y-1 sm:px-3">
                    <Link
                      to='/keys'
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      API Keys
                    </Link>
                  </div>
                  <div className="mt-3 px-2 space-y-1 sm:px-3">
                    <button
                      onClick={handleLogout}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              ) : undefined}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

const TopBanner = () => {
  return (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <a href="/subscription">
          <strong className="font-semibold">
            Free trial with no credit card required 👻
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Hey, we're not trying to be buzzkills here, but you'll need an active
          subscription to run campaigns. Give it a try today, no strings
          attached.
        </a>
      </p>
      <div className="flex flex-1 justify-end"></div>
    </div>
  )
}

const HarveyBenchmarkBanner = () => {
  return (
    <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <a href="https://consensys.net/diligence/blog/2023/04/benchmarking-smart-contract-fuzzers/">
          <strong className="font-semibold">
            Our Fuzzer is 🔥🔥🔥
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Check the article for the full comparison, in short, with Harvey you find as many violations in 19 mins as with others in 8 hours.
        </a>
      </p>
      <div className="flex flex-1 justify-end"></div>
    </div>
  )
}

