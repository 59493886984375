import { EmptyList } from '../../components/EmptyList'
import { Jdenticon } from '../../components/Jdenticon'
import { Link } from 'react-router-dom'
import { helpers } from '../../FaasReport'
const LineItem = ({
  campaignName,
  projectName,
  numOfFiles,
  dateStarted,
  id,
}) => {
  return (
    <li className="py-4 px-4 sm:p-6 hover:bg-gray-50">
      <Link to={'/campaigns/' + id}>
        <div className="flex space-x-3">
          <Jdenticon size={6} value={projectName ? projectName : 'n/a'} />
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">{projectName}</h3>
              <p className="text-sm text-gray-500">
                {helpers.timeSince(new Date(dateStarted), true)}
              </p>
            </div>
            <p className="text-sm text-gray-500">{campaignName}</p>
          </div>
        </div>
      </Link>
    </li>
  )
}

export const RecentCampaigns = ({ data }) => {
  return (
    <>
      <div className="pb-5 mb-5 border-b border-gray-200 lg:mb-0 lg:border-none">
        <h3 className="text-lg leading-6 font-medium text-gray-900 lg:text-white">
          Recent Campaigns
        </h3>
      </div>
      <div>
        <div className="bg-white overflow-hidden shadow sm:rounded-lg">
          <div className="py-0 sm:py-0">
            <ul className="divide-y divide-gray-200">
              {data.length > 0 ? (
                data.map((item) => {
                  return (
                    <LineItem
                      key={
                        item.id
                      }
                      id={item.id}
                      campaignName={item.name}
                      projectName={item?.project?.name}
                      numOfFiles={item.numSources}
                      dateStarted={item.submittedAt}
                    />
                  )
                })
              ) : (
                <EmptyList />
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
