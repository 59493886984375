import React from 'react'
import {getIdByBlockIndex} from '../../helpers'
// import { helpers } from '../../index'
// const { getIdByBlockIndex } = helpers

export const ColumnsHighlight = ({ startingLine, endingLine, startingColumn, endingColumn, index = 0, currentLine, issueLine }) => {
  // tabs have to count as 4 spaces
  const numberOfTabs = (issueLine.split('\t').length) - 1
  const charsWithoutTabs = parseInt(startingColumn, 10) - numberOfTabs
  const marginLeft = 1.5 + (numberOfTabs * 4 * 0.6) + (charsWithoutTabs * 0.6)
  const blockIdentifier = getIdByBlockIndex({ type: 'block', lineNumber: currentLine, index })
  const ownId = getIdByBlockIndex({ type: 'highlight', lineNumber: currentLine, index })

  const onHov = () => {
    const el = document.getElementById(blockIdentifier)
    el && el.classList.add('issue-block--highlighted')
  }

  const outHov = () => {
    const el = document.getElementById(blockIdentifier)
    el && el.classList.remove('issue-block--highlighted')
  }

  if (startingLine !== endingLine) {
    const leadingWhiteSpaceLength = issueLine.search(/\S/)
    const leadingWhiteSpace = issueLine.slice(0, leadingWhiteSpaceLength)
    const numberOfTabs = (leadingWhiteSpace.split('\t').length) - 1
    const charsWithoutTabs = leadingWhiteSpaceLength - numberOfTabs
    const marginLeft = 1.5 + (numberOfTabs * 4 * 0.6) + (charsWithoutTabs * 0.6)
    return (<div id={ownId} style={
    {
      'width': `3em`,
      'marginLeft': `${marginLeft}em`,
      'top': `${Math.floor(5 * index)}px`
    }
    } className='columns-highlight columns-highlight--multiple-lines'
      onMouseOver={onHov}
      onMouseOut={outHov}
      data-message={`This issue spans across lines ${startingLine} and ${endingLine}`}
    />)
  }

  return (
    <div id={ownId} style={
    {
      'width': `${Math.floor((parseInt(endingColumn + 1, 10) - parseInt(startingColumn, 10)) * 0.6)}em`,
      'marginLeft': `${marginLeft}em`,
      'top': `${Math.floor(5 * index)}px`
    }
    } className='columns-highlight'
      onMouseOver={onHov}
      onMouseOut={outHov}
    />
  )
}
