import React from 'react'

export const SourceFilesSectionHeading = () => {
  return (
    <div data-role="faas-report__all-source-files" className="my-8 pb-5 border-b border-gray-200">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Source Files
      </h3>
      <p className="mt-2 max-w-4xl text-sm text-gray-500">
      You can expand the source files and see the issues detected on each line.
      </p>
    </div>
  )
}
