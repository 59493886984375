import React, { Fragment, useEffect, useState } from 'react'

import { ArrowSmDownIcon } from '@heroicons/react/solid'
import { EmptyList } from '../../../components/EmptyList'

/**
 * This is the original properties table, now used only as a fallback when we fail
 * to decode properties.
 */

export const IndividualCoverageTable = ({ metadata }) => {
  const originalIndividualCoverage = metadata.individualCoverage || []

  const [sortKey, setSortKey] = useState('fileName')
  const [individualCoverage, setIndividualCoverage] = useState(
    originalIndividualCoverage
  )

  const tableCategories = [
    {
      label: 'File',
      key: 'fileName',
    },
    {
      label: 'Contract',
      key: 'contractName',
    },
    {
      label: '# of Branches',
      key: 'branchCoverage',
    },
    {
      label: 'Instruction %',
      key: 'instructionCoveragePercentage',
    },
    {
      label: '# of Instructions',
      key: 'instructionCoverage',
    },
  ]

  function sortArrayByKey(array, key) {
    return array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1
      } else if (a[key] > b[key]) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    const sortedArray = sortArrayByKey([...originalIndividualCoverage], sortKey)
    setIndividualCoverage(sortedArray)
  }, [sortKey])

  if(originalIndividualCoverage.length === 0) {
    return <EmptyList />
  }

  return (
    <div data-role="faas-report__properties-table" className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          data-role="faas-report__properties-table--inline-wrapper"
          className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
        >
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {tableCategories.map((category) => {
                    return (
                      <th
                        scope="col"
                        key={'cat--' + category.key}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <div className="flex">
                          <span className="pt-1">{category.label}</span>
                          <button onClick={() => setSortKey(category.key)}>
                            <ArrowSmDownIcon
                              className={`h-5 w-5 ${
                                sortKey === category.key
                                  ? 'text-gray-800'
                                  : 'text-gray-300'
                              }`}
                            />
                          </button>
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {individualCoverage.map((property, i) => {
                  return (
                    <Fragment
                      key={
                        'covData_' + property.fileName + property.contractName
                      }
                    >
                      <Row
                        // scrollToLine={scrollToLine}
                        even={(1 + i) % 2 === 0}
                        property={property}
                      />
                    </Fragment>
                  )
                })}
              </tbody>
              <tfoot className="border-none" />
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const Row = ({ property, even }) => {
  const {
    fileName,
    contractName,
    branchCoverage,
    instructionCoverage,
    instructionCoveragePercentage,
  } = property

  return (
    <tr className={`${even ? 'bg-gray-50' : 'bg-white'}`}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {fileName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {contractName.length && contractName.length >= 60 ? (
          <p dir="rtl" className="max-w-md overflow-x-auto hide-scrollbar">
            {contractName}
          </p>
        ) : (
          contractName
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {branchCoverage}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {instructionCoveragePercentage}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {instructionCoverage}
      </td>
    </tr>
  )
}
