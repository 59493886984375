import { Jdenticon } from '../../components/Jdenticon'
import { Transition } from '@headlessui/react'
import { getAuth0AuthorizationCodeLink } from '../../services'
import { useState } from 'react'

export const Header = ({ projectName, onClick }) => {
  const [showPopup, setShowPopup] = useState(false)


  return (
    <header className="bg-gray-800 pb-32 pt-10 mb-10">
      {/*<!-- This example requires Tailwind CSS v2.0+ -->*/}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">

        <div className="flex-1 min-w-0">
          <h2 className="ml-4 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
            {projectName}
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="sm:ml-3">
            <a
              type="button"
              href="https://fuzzing-docs.diligence.tools/getting-started-1/configuring-the-cli#subscriptions-and-api-key"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
            >
              How to use API Keys
            </a>
            <a
              type="button"
              href={getAuth0AuthorizationCodeLink()}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
            >
              Create new API Key
            </a>
          </span>
        </div>
      </div>

    </header>
  )
}


/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Back End Developer</h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
        >
          Edit
        </button>
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
        >
          Publish
        </button>
      </div>
    </div>
  )
}
