import {
  CAMPAIGN_STATUS,
  getCampaignRuntime,
  getNumberOfVulnerabilities,
} from '../utils'
import {
  ChevronRightIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  FolderIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
  LightningBoltIcon,
  CodeIcon
} from '@heroicons/react/solid'
import { Link, useHistory } from 'react-router-dom'

import { EmptyList } from './EmptyList'
import { helpers } from '../FaasReport'

export const CampaignsList = ({ SectionHeader, data = [], projectName }) => {
  return (
    <>
      {SectionHeader}
      <div className="space-y-6  bg-white shadow overflow-hidden sm:rounded-md  ">
        <ul className="divide-y divide-gray-200">
          {data.length > 0 ? (
            data.map((item) => {
              const numberOfVulnerabilities = getNumberOfVulnerabilities(item)
              return (
                <ListItem
                  key={`${item.name}-${item.submittedAt}`}
                  campaignName={item.name}
                  id={item.id}
                  projectName={projectName ? projectName : item?.project?.name}
                  projectId={item?.project?.id}
                  numOfIssues={numberOfVulnerabilities}
                  campaign={item}
                  target={item?.corpus?.target}
                />
              )
            })
          ) : (
            <EmptyList />
          )}
        </ul>
      </div>
    </>
  )
}



export const CampaignStartGuide = ({ SectionHeader, data = [], projectName }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const items = [


    {
      title: 'I have a Foundry project with Fuzz tests.',
      description: 'Get a campaign started in less than 2 minutes ⚡️',
      icon: LightningBoltIcon,
      url: "https://fuzzing-docs.diligence.tools/getting-started/fuzzing-foundry-projects",
      background: 'bg-pink-500',
    },
    {
      title: "I am using a different Ethereum framework.",
      description: 'Use Scribble to add properties and generate tests.',
      icon: CodeIcon,
      url: 'https://fuzzing-docs.diligence.tools/getting-started/fuzzing-non-foundry-projects',
      background: 'bg-yellow-500',
    },
  ]
  return (
    <>
      {SectionHeader}
      <div className=" py-4 sm:px-6 space-y-6  bg-white shadow overflow-hidden sm:rounded-md  ">
        <div>
          <h2 className="text-base font-semibold leading-6 text-gray-900">Start a new campaign</h2>
          <p className="mt-2text-sm text-gray-500">
            You don't have any active campaigns. Follow one of the guides below to get started.
          </p>
          <ul role="list" className="mt-4 grid grid-cols-1 gap-6   py-4 sm:grid-cols-2">
            {items.map((item, itemIdx) => (
              <li key={itemIdx} className="flow-root">
                <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                  <div
                    className={classNames(
                      item.background,
                      'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      <a href={item.url} target='_blank' rel="noreferrer"  className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <span>{item.title}</span>
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

const getLatestUpdate = ({ status, runTime, submittedAt, stoppedAt }) => {
  let content

  if (status === CAMPAIGN_STATUS.RUNNING) {
    // content = `Running for ${Math.round(runTime / 60)} minutes`
    content = `Running for ${runTime}`
  }
  if (status === CAMPAIGN_STATUS.STOPPED) {
    content = `Stopped ${helpers.timeSince(new Date(stoppedAt))} ago`
  }
  if (status === CAMPAIGN_STATUS.STOPPING) {
    content = `Currently being stopped`
  }
  if (status === CAMPAIGN_STATUS.STARTING) {
    content = `Campaign is starting`
  }
  if (status === CAMPAIGN_STATUS.IDLE) {
    content = `Created ${helpers.timeSince(new Date(submittedAt))} ago`
  }

  return content
}

const ListItem = ({
  projectName,
  numOfIssues,
  id,
  campaign,
  projectId,
  target,
}) => {
  const history = useHistory()
  const {
    submittedAt,
    startedAt,
    publicAccess,
    stoppedAt,
    status,
    name,
    numSources,
    error
  } = campaign

  const numOfFiles = numSources
  const runTime = getCampaignRuntime({ campaignMetadata: campaign })

  const getAdditionalText = () => {
    if (status === 'idle') {
      return 'Campaign not yet started'
    } else if (numOfIssues > 0) {
      return `${numOfIssues} issues detected`
    } else {
      return `No issues detected`
    }
  }

  return (
    <li className="block hover:bg-gray-50" name="campaigns-list-item">
      <Link to={`/campaigns/${id}`}>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">
                  {name}
                </p>
                <div className="inline-flex">
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className="w-32 mt-2 mr-4 flex items-center text-sm text-gray-500"
                  >
                    <FolderIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(`/projects/${projectId}`)
                      }}
                      className="truncate hover:underline"
                    >
                      {projectName}
                    </button>
                  </p>
                  <p className="mt-2 mr-4 flex items-center text-sm text-gray-500">
                    <DocumentIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                    <span className="truncate">
                      {numOfFiles + ' source files'}
                    </span>
                  </p>
                  {publicAccess ? (
                    <p className="mt-2 mr-4 flex items-center text-sm text-green-600">
                      <LockOpenIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" />
                      <span className="truncate">{`Public`}</span>
                    </p>
                  ) : (
                    <p className="mt-2 mr-4 flex items-center text-sm text-gray-500">
                      <LockClosedIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                      <span className="truncate">{`Private`}</span>
                    </p>
                  )}
                  {target ? (
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <RefreshIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                      <span className="truncate">{`Reused corpus`}</span>
                    </p>
                  ) : undefined}
                </div>
              </div>
              <div className="hidden lg:block">
                <p className="text-sm text-gray-900">
                  {getLatestUpdate({
                    status,
                    runTime,
                    submittedAt,
                    stoppedAt,
                  })}
                </p>
                <div className="flex flex-row">
                  <p className="mt-2 pr-2 flex items-center text-sm text-gray-500">
                    <ExclamationCircleIcon
                      className={`flex-shrink-0 mr-1.5 h-5 w-5 ${numOfIssues > 0 ? 'text-yellow-300' : 'text-gray-400'
                        }`}
                    />
                    {getAdditionalText()}
                  </p>
                  {error ? <p className="mt-2 flex items-center text-sm text-gray-500">
                    <ExclamationCircleIcon
                      className={`flex-shrink-0 mr-1.5 h-5 w-5 text-red-300`}
                    />
                    {"Campaign errored"}
                  </p> : undefined}
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      </Link>
    </li>
  )
}
