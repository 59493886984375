import './style.scss'

import {
  AdvancedStatistics,
  CardFrame,
  IndividualCoverageTable,
  IssuesTable,
} from '../tailwind'
import {
  CoverageChart,
  OtherAnalysisInfo,
  FaaSReportLoading as ReportLoading,
  ReportLoadingError,
} from './components'
import React, { useEffect, useState } from 'react'

import { AdvancedCharts } from './components/AdvancedCharts'
import { ExclamationIcon } from '@heroicons/react/solid'
import FaaSAnalysesSource from './FaasAnalysesSource'
import { Report } from 'mythx-report-helper'
import { SourceFilesSectionHeading } from '../tailwind/SourceFilesSectionHeading'
import { PropertiesTableWrapper } from '../tailwind/PropertiesTableWrapper'

export const FaaSReport = ({
  uuid,
  analysisInput,
  analysisIssues,
  reportLoadError,
  liveUpdate,
  groupId,
  analysisMeta,
  proMode = false,
}) => {
  const [visibleContracts, setVisibleContracts] = useState([
    { name: 'test', visible: false },
  ])
  const [issuesReport, setIssuesReport] = useState(undefined)
  const issueFilters = {
    displayIgnored: false,
    severity: ['low', 'medium', 'high'],
  }
  const [charts, setCharts] = useState({
    coverageChart: [],
    coverageChartUnit: [],
    branchCoverageChart: [],
    branchCoverageChartUnit: [],
    pathCoverageChart: [],
    pathCoverageChartUnit: [],
    lineCoverage: [],
    transactionDepthChart: {},
  })

  const toggleExpandSourceContractCode = (name, onlyOpen = false) => {
    try {
      if (visibleContracts) {
        let visibleContract = visibleContracts.filter(
          (visCon) => visCon.name === name
        )
        visibleContract[0].visible = !visibleContract[0].visible
        if (!onlyOpen || (onlyOpen && visibleContract[0].visible)) {
          let newVisibleContracts = visibleContracts.filter(
            (visCon) => visCon.name !== name
          )
          newVisibleContracts.push(visibleContract[0])
          setVisibleContracts(newVisibleContracts)
        }
      }
    } catch (error) {
      console.error('Could not display source file')
    }
  }

  const reportLoadSuccess = analysisIssues && analysisInput

  const updateCharts = (report) => {
    try {
      const coverageChart =
        report.issuesWithMetrics[0].meta['PercentageCoverageOverTime']
      const coverageChartUnit =
        report.issuesWithMetrics[0].meta['PercentageCoverageOverTimeUnit']

      const lineCoverage = report.issuesWithMetrics[0].meta['lineCoverage']

      const branchCoverageChart =
        report.issuesWithMetrics[0].meta['BranchCoverageOverTime']
      const branchCoverageChartUnit =
        report.issuesWithMetrics[0].meta['BranchCoverageOverTimeUnit']

      const pathCoverageChart =
        report.issuesWithMetrics[0].meta['PathCoverageOverTime']
      const pathCoverageChartUnit =
        report.issuesWithMetrics[0].meta['PathCoverageOverTimeUnit']

      const transactionDepthChart =
        report.issuesWithMetrics[0].meta['transactionDepth']

      if (
        coverageChart &&
        coverageChartUnit &&
        lineCoverage &&
        branchCoverageChart &&
        branchCoverageChartUnit &&
        pathCoverageChart &&
        pathCoverageChartUnit &&
        transactionDepthChart
      ) {
        setCharts({
          coverageChart,
          coverageChartUnit,
          lineCoverage,
          branchCoverageChart,
          branchCoverageChartUnit,
          pathCoverageChart,
          pathCoverageChartUnit,
          transactionDepthChart,
        })
      }
    } catch (e) {
      console.error('Chart data not available.', e)
    }
  }

  useEffect(() => {
    if (reportLoadSuccess) {
      // generates Report if input and report are available
      let report = new Report(analysisIssues, analysisInput, issueFilters, true)
      setIssuesReport(report)
      setVisibleContracts(
        report.sourceFilesNames.map((fileName) => ({
          visible:
            visibleContracts.filter((c) => c.name === fileName)?.[0]?.visible ||
            false,
          name: fileName,
        }))
      )
      updateCharts(report)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLoadSuccess])

  useEffect(() => {
    if (liveUpdate && liveUpdate.type && liveUpdate.issues) {
      const { type, issues } = liveUpdate

      if (type === 'metrics') {
        issuesReport.updateMetrics(issues)
        setIssuesReport(issuesReport)
        updateCharts(issuesReport)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveUpdate])

  if (!reportLoadError && !issuesReport) {
    return <ReportLoading />
  } else {
    return reportLoadError ? (
      <ReportLoadingError groupId={groupId} error={reportLoadError} />
    ) : (
      <div>
        {proMode && issuesReport?.issuesWithMetrics?.[0]?.meta ? (
          <>
            <AdvancedStatistics
              numberOfIssues={issuesReport.getNumberOfIssues()}
              metadata={issuesReport.issuesWithMetrics[0].meta}
            />
            <div
              data-role="faas-report__coverage-chart"
              className="my-8 pb-5 border-b border-gray-200"
            >
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Coverage Charts
              </h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                These graphs shows the progression of coverage by the Diligence
                Fuzzing analysis engine on several categories.
              </p>
            </div>

            <AdvancedCharts charts={charts} />

            {issuesReport.issuesWithMetrics[0].meta.individualCoverage ? (
              <>
                {' '}
                <div
                  data-role="faas-report__individual-coverage-chart"
                  className="my-8 pb-5 border-b border-gray-200"
                >
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Individual Coverage
                  </h3>
                  <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    These graphs shows the coverage by contract
                  </p>
                </div>
                <IndividualCoverageTable
                  metadata={issuesReport.issuesWithMetrics[0].meta}
                />
              </>
            ) : undefined}
          </>
        ) : (
          <>
            <div
              data-role="faas-report__coverage-chart"
              className="my-8 pb-5 border-b border-gray-200"
            >
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Coverage Chart
              </h3>
              <p className="mt-2 max-w-4xl text-sm text-gray-500">
                This graph shows the progression of coverage by the Diligence
                Fuzzing analysis engine.
              </p>
            </div>
            <CardFrame>
              {charts.coverageChart.length &&
                charts.coverageChart.length > 0 ? (
                <div className={`row coverage-chart-wrapper`}>
                  <CoverageChart
                    data={charts.coverageChart}
                    unit={charts.coverageChartUnit}
                  />
                </div>
              ) : (
                <div className={`row coverage-chart-wrapper`}>
                  <CoverageChart
                    empty={true}
                    data={[{ x: 0, y: 0 }]}
                    unit={'seconds'}
                  />
                </div>
              )}
            </CardFrame>
          </>
        )}

        {issuesReport.propertiesAvailable ? <PropertiesTableWrapper
          displayNotDetected={false}
          issuesReport={issuesReport}
          toggleExpandSourceContractCode={toggleExpandSourceContractCode}
        /> : <>
          <div data-role="faas-report__properties-table-title" className="my-8 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Properties
            </h3>
          </div>
          <IssuesTable
            properties={issuesReport}
            toggleExpandSourceContractCode={toggleExpandSourceContractCode}
          /></>}

        <SourceFilesSectionHeading />
        <CheatcodesBanner properties={issuesReport} />
        <FaaSAnalysesSource
          mythxReport={issuesReport}
          uuid={uuid}
          toggleExpandSourceContractCode={toggleExpandSourceContractCode}
          visibleContracts={visibleContracts}
          lineCoverage={charts.lineCoverage}
          ignoredIssuesDisplayed={issueFilters.displayIgnored}
        />
        <OtherAnalysisInfo issuesReport={issuesReport} />
      </div>
    )
  }
}

const CheatcodesBanner = ({ properties }) => {
  // showing/hiding the cheatcodes
  const [showCheatcodes, setShowCheatcodes] = useState(false)

  if (properties.unsupportedCheatCodeIssues.length === 0) {
    return <></>
  }
  // extract cheat codes
  // this is a bit hacky, but it works until we change the format of issue

  // This regex matches unknown cheat code messages
  const regexUnknown = /unknown cheat-code function with signature '(\w+)'/;
  // This regex matches standard cheat code messages
  const regexStandard = /function\s+(.*?)\s+was/;
  const cheatCodes = properties.unsupportedCheatCodeIssues
    .map((i) => i.description.tail)
    .map((c) => {
      if (c.indexOf('An unknown cheat-code function with signature') > -1) {
        const match = c.match(regexUnknown)
        if (match === null) {
          return null
        }
        // capitalize first letter
        return match[0].charAt(0).toUpperCase() + match[0].slice(1)
      } else {
        const match = c.match(regexStandard)
        if (match === null) {
          return null
        }
        // capitalize first letter
        return match[1].slice(1, match[1].length - 1)
      }
    })

  // remove duplicates
  const deduped = [...new Set(cheatCodes)]

  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-8">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Some Foundry cheat codes that you used are currently not supported.{' '}
            <button
              onClick={() => { setShowCheatcodes(!showCheatcodes) }}
              className="font-medium  text-yellow-700 underline hover:text-yellow-600">
              {!showCheatcodes ? 'See which ones' : 'Hide'}
            </button>
          </p>
          {/* Listing the deduped cheatcodes */}
          {showCheatcodes ? <ul className="mt-3 font-mono list-disc list-inside text-sm text-yellow-700">
            {deduped.map((c) => (
              <li key={c}>{c}</li>
            ))}
          </ul> : undefined}
        </div>
      </div>
    </div>
  )
}
