import {
  ExclamationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/solid'
import React, { useState } from 'react'

import { DebugPanel } from './DebugPanel'
import { getIdByBlockIndex } from '../../helpers'

export const IssueBlock = ({
  id,
  tail,
  head,
  testCases,
  debugInfo,
  currentLine,
  currentBlock,
  ignored,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const blockIdentifier = getIdByBlockIndex({
    type: 'block',
    lineNumber: currentLine,
    index: currentBlock,
  })
  const highlightIdentifier = getIdByBlockIndex({
    type: 'highlight',
    lineNumber: currentLine,
    index: currentBlock,
  })

  const onHov = () => {
    const el = document.getElementById(highlightIdentifier)
    el && el.classList.add('columns-highlight--highlighted')
  }

  const outHov = () => {
    const el = document.getElementById(highlightIdentifier)
    el && el.classList.remove('columns-highlight--highlighted')
  }

  return (
    <>
      <div
        className="mt-4 bg-gray-50 shadow overflow-hidden"
        onMouseOver={onHov}
        onMouseOut={outHov}
        id={blockIdentifier}
        data-id={id}
      >
        <div className="flex px-6 py-6 sm:px-6">
          <div className="min-w-0 flex-1 flex-col sm:flex sm:justify-between">
            <div className="flex items-center justify-between">
              <div className="flex">
                <ExclamationCircleIcon
                  className="flex-shrink-0 mr-1.5 h-6 w-6 text-indigo-400"
                  aria-hidden="true"
                />
                <p className="text-base uppercase font-medium text-indigo-600 truncate">
                  {`${head} ${ignored ? '(ignored)' : ''}`}
                </p>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="mt-2 mr-4 flex items-center text-sm text-gray-500 sm:mt-0">
                  {tail}
                </p>
              </div>
            </div>
          </div>
          {isExpanded ? (
            <button
              type="button"
              onClick={() => setIsExpanded(false)}
              className="m-auto inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <MinusCircleIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Less
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setIsExpanded(true)}
              className="m-auto inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusCircleIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              More
            </button>
          )}
        </div>
      </div>
      {isExpanded ? (
        <div className="bg-gray-100 shadow overflow-hidden px-4 py-4 sm:px-6">
          <DebugPanel testCases={testCases} blockId={blockIdentifier} debugInfo={debugInfo}/>
        </div>
      ) : undefined}
    </>
  ) 
}
