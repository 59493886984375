import { BreadCrumbs } from '../../../../components/BreadcrumbsAdmin'
import { getMonthName } from '../../../../utils'

export const Header = ({ userId, userEmail, year, month }) => {
  const pages = [
    { name: 'Admin', href: '/admin', current: false },
    { name: userEmail, href:`/admin/${userId}`, current: false },
    { name: `${getMonthName(month)} ${year}`, href:`/admin/${userId}/${year}/${month}`, current: true },
  ]

  return (
    <header className="bg-gray-800 pb-32 pt-10 mb-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
            {`${getMonthName(month)} ${year} usage for ${userEmail}`}
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <BreadCrumbs pages={pages}/>
        </div>
      </div>
    </header>
  )
}

