import {
  CAMPAIGN_STATUS,
  downloadObjectAsJson,
  getCampaignRuntime,
  normalizeMs,
  twoDecimals,
} from '../../utils'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  getCampaignInput as getInput,
  getCampaignIssues as getIssues,
  getRawReport,
  getCampaignStatus as getStatus,
  startCampaign,
  stopCampaign,
} from '../../services'

import { AuthContext } from '../../App'
import { FaaSReport } from '../../FaasReport'
import { Header } from './Header'
import LoadingPage from '../../LoadingPage'
import { LoadingSection } from '../../components/LoadingSection'
import { NotFoundPage } from '../../components/NotFoundPage'
import { QuickStatistics } from '../../components/QuickStatistics'
import React from 'react'
import { SettingsSlide } from './SettingsSlide'
import { ToasterContent } from '../../components/ToasterContent'
import { errorMessages } from '../../services/errorMessages'
import { toaster } from '../..'
import { useParams } from 'react-router-dom'

const LIVE_UPDATES_STORAGE = 'faas-live-updates'
const PRO_MODE_STORAGE = 'faas-pro-mode'
const DISPLAY_RUNTIME_EXCEPTIONS_STORAGE = 'faas-display-runtime-exceptions'

export const OwnerContext = createContext(false)
export const Report = () => {
  const auth0 = useContext(AuthContext)

  const liveUpdatesUserPreference =
    JSON.parse(localStorage.getItem(LIVE_UPDATES_STORAGE)) || true

  const proModeUserPreference =
    JSON.parse(localStorage.getItem(PRO_MODE_STORAGE)) || false

  const runtimeExceptionUserPreference =
    JSON.parse(localStorage.getItem(DISPLAY_RUNTIME_EXCEPTIONS_STORAGE)) ||
    false

  const [issues, setIssues] = useState()
  const [input, setInput] = useState()

  // record if data fetching is in progress
  const [campaignMetadata, setCampaignMetadata] = useState()
  const [campaignNotFound, setCampaignNotFound] = useState(false)
  const [nextIssues, setNextIssues] = useState()
  const [liveUpdate, setLiveUpdate] = useState(liveUpdatesUserPreference)
  const [intervalId, setIntervalId] = useState()
  const [updates, setUpdates] = useState()
  const [stats, setStats] = useState()
  const [displaySettings, setDisplaySettings] = useState(false)
  const [proMode, setProMode] = useState(proModeUserPreference)
  const [displayRuntimeException, setDisplayRuntimeException] = useState(
    runtimeExceptionUserPreference
  )
  const [isInit, setInit] = useState(false)

  const { campaignId } = useParams()

  const isOwner = () => {
    if (campaignMetadata?.owner && auth0?.user) {
      const { owner } = campaignMetadata
      const { sub } = auth0.user
      return owner === sub
    } else {
      return false
    }
  }

  const LIVE_UPDATE_FETCH_INTERVAL = 10 * 1000

  const toggleProMode = () => {
    setProMode(!proMode)
    localStorage.setItem(PRO_MODE_STORAGE, !proMode)
  }

  const toggleDisplayRuntimeExceptions = () => {
    setDisplayRuntimeException(!displayRuntimeException)
    localStorage.setItem(
      DISPLAY_RUNTIME_EXCEPTIONS_STORAGE,
      !displayRuntimeException
    )
  }

  // Handles downloading the raw report, including output and input
  const handleDownloadRawReport = async () => {
    try {
      const data = await getRawReport(campaignId, auth0)
      if (!(data && data.result && data.requests)) {
        throw new Error('Raw data not available', data)
      }
      downloadObjectAsJson({ data, name: `raw--${campaignId}` })
    } catch (e) {
      console.error(e)
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue downloading your raw campaign report.`}
        />,
        { duration: 1000 }
      )
    }
  }

  // Using aa useRef to store the fetching status instead of useState will ensure that we're
  // always working with the most up-to-date fetching status.
  const isFetchingRef = useRef(false)

  // Fetches metadata, issues and inputs from faas-api
  // handles the initial fetch
  const fetchData = async ({ initialFetch = false }) => {
    // if we are already fetching data, don't fetch again because it will cause
    // requests to be queued up and slow down the UI
    if (isFetchingRef.current) {
      console.log("Skipping fetch because we're already fetching")
      return
    }
    try {
      //setIsFetching(true)
      isFetchingRef.current = true
      const metadata = await getStatus(campaignId, auth0)
      const currentIssues = await getIssues(campaignId, auth0)
      console.log('currentIssues', currentIssues[0].issues)

      // this is a hotfix to remove runtime exceptions from the issues
      if (!displayRuntimeException) {
        currentIssues[0].issues = currentIssues[0].issues.filter(
          (issue) => !issue?.extra?.runtimeException
        )
      }

      setCampaignMetadata(metadata)

      if (metadata) {
        setCampaignNotFound(false)
      }

      if (metadata.status === CAMPAIGN_STATUS.STOPPED && liveUpdate) {
        // we don't need to live update a stopped campaign
        disableLiveUpdate()
      }
      if (initialFetch && metadata.status === CAMPAIGN_STATUS.STARTING) {
        // we need to live update a campaign which is starting
        enableLiveUpdate()
      }
      if (initialFetch || !issues || !issues[0]) {
        setIssues(currentIssues)
        setNextIssues(currentIssues)
        setInput(await getInput(campaignId, auth0))
      } else {
        const numberOfPendingIssues =
          currentIssues[0].issues.length - issues[0].issues.length
        setNextIssues(currentIssues)
        setUpdates({
          ...updates,
          type: 'metrics',
          issues: currentIssues,
          numberOfPendingIssues,
        })
      }
      isFetchingRef.current = false
    } catch (e) {
      if (e.message === errorMessages.CAMPAIGN_NOT_FOUND) {
        setCampaignNotFound(true)
      }
      console.error(e.message)
    } finally {
      // we are done fetching data, so we can set isFetching to false
      isFetchingRef.current = false
    }
  }

  const renderNewIssues = () => {
    fetchData({ initialFetch: true })
  }

  const disableLiveUpdate = () => {
    console.log('❌ disabling live update')
    clearInterval(intervalId)
    setLiveUpdate(false)
  }

  const enableLiveUpdate = () => {
    setLiveUpdate(true)
    console.log('✅ enabling live update')
    const newIntervalId = setInterval(
      () => fetchData({ initialFetch: false }),
      LIVE_UPDATE_FETCH_INTERVAL
    )
    setIntervalId(newIntervalId)
  }

  const toggleLiveUpdate = (saveState = true) => {
    if (liveUpdate && intervalId) {
      disableLiveUpdate()
    } else {
      enableLiveUpdate()
    }
    if (saveState) {
      localStorage.setItem(LIVE_UPDATES_STORAGE, !liveUpdate)
    }
  }

  // Toggle handler to start and stop campaigns
  const toggleCampaign = async () => {
    if (campaignMetadata.status === 'running') {
      try {
        await stopCampaign(campaignMetadata.id, auth0)
        toaster.notify(
          <ToasterContent label={`Your campaign was stopped.`} />,
          { duration: 1000 }
        )
        if (liveUpdate) {
          toggleLiveUpdate(false)
        }
        setTimeout(async () => await fetchData({ initialFetch: false }), 500)
      } catch (e) {
        toaster.notify(
          <ToasterContent
            error={true}
            label={`There was an issue stopping your campaign.`}
          />,
          { duration: 1000 }
        )
      }
    } else {
      try {
        await startCampaign(campaignMetadata.id, auth0)
        toaster.notify(
          <ToasterContent label={`Your campaign was started.`} />,
          { duration: 1000 }
        )
        setTimeout(() => window.location.reload(), 500)
      } catch (e) {
        toaster.notify(
          <ToasterContent
            error={true}
            label={`There was an issue starting your campaign.`}
          />,
          { duration: 1000 }
        )
      }
    }
  }

  // Toggle handler to display the preferences tab,
  // which allows for campaign name and project changes
  const toggleSettings = async (reload = false) => {
    if (displaySettings) {
      setDisplaySettings(false)
      if (reload) {
        await fetchData({ initialFetch: false })
      }
    } else {
      setDisplaySettings(true)
    }
  }

  // we use this to trigger the report re-render when the live update is toggled
  // it's a hotfix, tech debt, but we can't waste a lot of time on a minor thing
  useEffect(() => {
    fetchData({ initialFetch: true })
  }, [displayRuntimeException])

  // This use effect handles the update of stats
  useEffect(() => {
    const rawStats = nextIssues?.[0]?.meta
    let stats = { ...rawStats }
    if (rawStats?.InstructionCoverageOverTime) {
      stats.InstructionCoverageOverTime = JSON.parse(
        rawStats.InstructionCoverageOverTime
      )
      stats.totalCodeCoverageInstructions =
        stats.InstructionCoverageOverTime.slice(-1)[0][1]
    }
    if (rawStats?.PercentageCoverageOverTime) {
      stats.PercentageCoverageOverTime = JSON.parse(
        rawStats.PercentageCoverageOverTime
      )
      const coverageIsAvailable =
        stats.PercentageCoverageOverTime.slice(-1).length === 1
      stats.totalCodeCoverage = coverageIsAvailable
        ? stats.PercentageCoverageOverTime.slice(-1)[0][1]
        : 0
      stats.duration = Math.floor(
        coverageIsAvailable
          ? stats.PercentageCoverageOverTime.slice(-1)[0][0] / 1000000000
          : 0
      )
    }

    if (rawStats?.residualRiskEstimate && rawStats?.residualRiskEstimate > 0) {
      stats.residualRisk = twoDecimals(rawStats.residualRiskEstimate * 100)
    }

    if (rawStats?.LineCoverage) {
      stats.LineCoverage = JSON.parse(rawStats.LineCoverage)
    }

    setStats(stats)
  }, [nextIssues])

  const clearIntervals = () => {
    const interval_id = setInterval(() => {}, 9999)
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i)
    }
  }
  useEffect(() => {
    fetchData({ initialFetch: true })
    if (liveUpdatesUserPreference) {
      const newIntervalId = setInterval(() => {
        if (campaignMetadata && campaignMetadata.status === 'running') {
          fetchData({ initialFetch: false })
        }
      }, LIVE_UPDATE_FETCH_INTERVAL)
      setIntervalId(newIntervalId)
    }
    return () => {
      clearIntervals()
    }
  }, [isInit])

  // Effect that runs once the first issue is discovered.
  // This is necessary to trigger the creation of a Report, which can only exist when there are issues
  useEffect(() => {
    if (!isInit) {
      const vulns = campaignMetadata?.numVulnerabilities
      const totalVulns = vulns
        ? Object.values(vulns).reduce((a, b) => a + b, 0)
        : 0

      if (totalVulns > 0) {
        setInit(true)
      }
    } else {
      return
    }
  }, [campaignMetadata])

  if (campaignNotFound) {
    return <NotFoundPage title="Campaign not found" timeToRefresh />
  }

  if (!issues || !input || !campaignMetadata) {
    return (
      <LoadingPage
        onClick={() => fetchData({ initialFetch: true })}
        timeToRefresh
      />
    )
  }

  const amountHiddenIssues =
    nextIssues?.[0]?.issues?.length - issues?.[0]?.issues?.length || 0

  const normalizedTimeLimit = campaignMetadata?.timeLimit
    ? normalizeMs({
        runtimeInMs: Math.floor(campaignMetadata?.timeLimit * 1000),
      })
    : undefined

  return (
    <OwnerContext.Provider value={isOwner()}>
      <React.Fragment key={campaignId}>
        <Header
          campaignMetadata={campaignMetadata}
          proMode={proMode}
          liveUpdatesStatus={liveUpdate}
          toggleLiveUpdates={toggleLiveUpdate}
          toggleCampaign={toggleCampaign}
          toggleSettings={toggleSettings}
          toggleProMode={toggleProMode}
          displayRuntimeExceptions={displayRuntimeException}
          toggleDisplayRuntimeExceptions={toggleDisplayRuntimeExceptions}
          renderNewIssues={renderNewIssues}
          numOfNewIssues={amountHiddenIssues}
          handleDownloadRawReport={handleDownloadRawReport}
        />
        {campaignMetadata?.status === CAMPAIGN_STATUS.STARTING ? (
          <LoadingSection />
        ) : (
          <main className="-mt-32">
            <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
              <QuickStatistics
                isRunning={campaignMetadata.status === 'running'}
                timeLimit={normalizedTimeLimit}
                testCasesPerSecond={
                  stats?.executionsPerSecond
                    ? Math.floor(stats?.executionsPerSecond)
                    : '0'
                }
                totalCodeCoverage={
                  stats?.totalCodeCoverage
                    ? stats.totalCodeCoverage + '%'
                    : '0%'
                }
                residualRisk={stats?.residualRisk}
                // duration={`${Math.round(campaignMetadata.runTime / 60)} minutes`}
                duration={getCampaignRuntime({ campaignMetadata })}
              />
              <FaaSReport
                fuzzingMode={true}
                uuid={undefined}
                groupId={undefined}
                analysisIssues={issues}
                analysisInput={input}
                liveUpdate={updates}
                proMode={proMode}
              />
              <SettingsSlide
                show={displaySettings}
                campaignMetadata={campaignMetadata}
                onClose={toggleSettings}
              />
            </div>
          </main>
        )}
      </React.Fragment>
    </OwnerContext.Provider>
  )
}
