import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon,
  RefreshIcon,
  StatusOnlineIcon,
} from '@heroicons/react/solid'
import { EyeIcon, XIcon } from '@heroicons/react/outline'
import {
  disableShareReport,
  enableShareReport,
  updateCampaignName,
} from '../../services'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { Dropdown } from './Dropdown'
import { Link } from 'react-router-dom'
import { OwnerContext } from '.'
import { PromoBanner } from '../../FaasReport/components/tailwind/PromoCards'
import { ToasterContent } from '../../components/ToasterContent'
import { Transition } from '@headlessui/react'
import { toaster } from '../..'

export const Header = ({
  campaignMetadata,
  liveUpdatesStatus,
  toggleLiveUpdates,
  toggleSettings,
  renderNewIssues,
  numOfNewIssues,
  toggleCampaign,
  toggleProMode,
  displayRuntimeExceptions,
  toggleDisplayRuntimeExceptions,
  proMode,
  handleDownloadRawReport,
}) => {
  const { name, status, submittedAt, publicAccess } = campaignMetadata

  const auth0 = useContext(AuthContext)
  const isOwner = useContext(OwnerContext)
  const [showOptions, setShowOptions] = useState(false)
  const [showReadOnlyModeBanner, setShowReadOnlyModeBanner] = useState(!isOwner)
  const [titleEditMode, setTitleEditMode] = useState(false)
  const [campaignName, setCampaignName] = useState(name)
  const [shareReport, setShareReport] = useState(publicAccess)
  const startedAt = new Date(submittedAt).toLocaleString()
  const projectName = campaignMetadata?.project?.name
  const projectId = campaignMetadata?.project?.id
  const vulns = campaignMetadata?.numVulnerabilities
  const totalVulns = vulns ? Object.values(vulns).reduce((a, b) => a + b, 0) : 0

  const handleCampaignNameUpdate = async () => {
    try {
      setTitleEditMode(false)
      await updateCampaignName(campaignMetadata.id, campaignName, auth0)
      toaster.notify(<ToasterContent label={`Campaign name updated.`} />, {
        duration: 1000,
      })
    } catch (e) {
      console.error(e)
      setCampaignName(name)
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue updating your campaign title. Please try again.`}
        />,
        { duration: 1000 }
      )
    }
  }

  const toggleShareReport = async () => {
    try {
      let successMessage
      if (shareReport) {
        await disableShareReport(campaignMetadata.id, auth0)
        setShareReport(false)
        successMessage = 'Report sharing disabled.'
      } else {
        await enableShareReport(campaignMetadata.id, auth0)
        setShareReport(true)
        successMessage = 'Report sharing enabled.'
      }
      toaster.notify(<ToasterContent label={successMessage} />, {
        duration: 1000,
      })
    } catch (e) {
      console.error(e)
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue updating your report share status. If the error persists, contact support.`}
        />,
        { duration: 1000 }
      )
    }
  }

  const {
    corpus: { target },
  } = campaignMetadata

  const getCorpusLink = (corpusTarget) => {
    if (corpusTarget.indexOf('cmp_') > -1) {
      return `/campaigns/${corpusTarget}`
    } else if (corpusTarget.indexOf('prj_') > -1) {
      return `/projects/${corpusTarget}`
    }
    return ''
  }

  const handleCancel = () => {
    setTitleEditMode(false)
    setCampaignName(name)
  }

  return (
    <header className="bg-gray-800 pb-32 pt-10 mb-10">
      <PromoBanner />
      {showReadOnlyModeBanner ? (
        <ReadOnlyModeBanner onClose={() => setShowReadOnlyModeBanner(false)} />
      ) : undefined}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <div className="flex">
            {titleEditMode ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleCampaignNameUpdate()
                }}
              >
                <div className="flex">
                  <TitleInput
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    handleClose={handleCancel}
                  />
                  <button
                    type="submit"
                    onClick={handleCampaignNameUpdate}
                    className="pl-6  whitespace-nowrap text-right text-base  font-medium text-gray-400 hover:text-white"
                  >
                    Confirm
                  </button>
                  <button
                    onClick={handleCancel}
                    className="px-4  whitespace-nowrap text-right text-base  font-medium text-gray-500 hover:text-white"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <>
                <h2 name="campaign-page-name" className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate ">
                  {campaignName}
                </h2>
                {isOwner ? (
                  <PencilIcon
                    onClick={() => {
                      setTitleEditMode(true)
                    }}
                    className={` text-gray-600 h-6 w-6 ml-2 pointer hover:text-white`}
                  />
                ) : undefined}
              </>
            )}
          </div>

          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            {projectName ? (
              isOwner ? (
                <Link
                  to={`/projects/${projectId}`}
                  className="place-self-center"
                >
                  <span className="mt-2 flex items-center text-base text-gray-300 hover:underline">
                    <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
                    {projectName}
                  </span>
                </Link>
              ) : (
                <span className="mt-2 flex items-center text-base text-gray-300 ">
                  <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
                  {projectName}
                </span>
              )
            ) : undefined}

            <div className="mt-2 flex items-center text-base text-gray-300">
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
              Created at {startedAt}
            </div>
            <div className="mt-2 flex items-center text-base text-gray-300">
              <ExclamationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
              {totalVulns} Issues
            </div>
            <div
              className={`mt-2 flex items-center text-base text-gray-100 rounded-md ${shareReport ? 'bg-green-600 px-2 py-1' : undefined
                } `}
            >
              {shareReport ? (
                <LockOpenIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-100" />
              ) : (
                <LockClosedIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
              )}
              {`${shareReport ? 'Public' : 'Private'} report`}
            </div>
            {target ? (
              <Link to={getCorpusLink(target)}>
                <span className="mt-2 flex items-center text-base text-gray-300 hover:underline">
                  <RefreshIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />
                  Reused corpus
                </span>
              </Link>
            ) : undefined}
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <span className="sm:ml-3">
            <RefreshIssuesButton
              disabled={numOfNewIssues === 0}
              onClick={renderNewIssues}
              numOfNewIssues={numOfNewIssues}
            />
          </span>
          <span className=" sm:block ml-3">
            <button
              disabled={status !== 'running'}
              type="button"
              className={`${status !== 'running' ? 'cursor-not-allowed' : undefined
                } disabled:opacity-50  inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500`}
              onClick={toggleLiveUpdates}
            >
              <StatusOnlineIcon
                className={`-ml-1 mr-2 h-5 w-5 ${liveUpdatesStatus ? 'text-pink-400' : 'text-gray-300'
                  }`}
              />
              {`Live updates`}
            </button>
          </span>

          <span className=" sm:block ml-3">
            <Dropdown
              show={showOptions}
              setShowOptions={setShowOptions}
              liveUpdatesStatus={liveUpdatesStatus}
              toggleLiveUpdates={toggleLiveUpdates}
              toggleSettings={toggleSettings}
              campaignStatus={status}
              renderNewIssues={renderNewIssues}
              toggleCampaign={toggleCampaign}
              handleDownloadRawReport={handleDownloadRawReport}
              toggleProMode={toggleProMode}
              displayRuntimeExceptions={displayRuntimeExceptions}
              toggleDisplayRuntimeExceptions={toggleDisplayRuntimeExceptions}
              proMode={proMode}
              shareReport={shareReport}
              toggleShareReport={toggleShareReport}
            />
          </span>
        </div>
      </div>
      {campaignMetadata?.error ? (
        <div className="mt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <ErrorMessage message={campaignMetadata.error} />
        </div>
      ) : undefined}
      <RefreshIssuesBanner
        numOfNewIssues={numOfNewIssues}
        display={numOfNewIssues > 0}
        onClick={renderNewIssues}
      />
    </header>
  )
}

const RefreshIssuesButton = ({ onClick, numOfNewIssues }) => {
  return (
    <>
      <button
        disabled={numOfNewIssues === 0}
        onClick={onClick}
        type="button"
        className={`${numOfNewIssues === 0 ? 'cursor-not-allowed' : undefined
          } disabled:opacity-50 relative  inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500`}
      >
        <RefreshIcon className="h-5 w-5" />
        {numOfNewIssues > 0 ? (
          <span className="absolute content-center justify-center flex -top-2 -right-2 block h-6 w-6 rounded-full ring-2 bg-indigo-200 text-gray-700">
            {numOfNewIssues}
          </span>
        ) : undefined}
      </button>
    </>
  )
}

const RefreshIssuesBanner = ({ display, numOfNewIssues, onClick }) => {
  return (
    <Transition
      show={display}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed shadow-md z-10 inset-x-0 bottom-8 w-96 mx-auto px-8 py-4 rounded-md bg-indigo-500">
        <div className="flex content-center justify-center">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-gray-100"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-md text-gray-100">{`${numOfNewIssues} new issues were found`}</p>
            <p className="mt-3 text-md md:mt-0 md:ml-6">
              <button
                onClick={onClick}
                className="whitespace-nowrap font-medium text-gray-100 hover:text-gray-200"
              >
                Refresh
              </button>
            </p>
          </div>
        </div>
      </div>
    </Transition>
  )
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const TitleInput = ({ campaignName, setCampaignName, handleClose }) => {
  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      handleClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])
  return (
    <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
      <input
        autoFocus
        type="text"
        name="name"
        id="name"
        className="block w-full border-0 border-b border-transparent bg-gray-600 focus:border-indigo-600 focus:ring-0 text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate  sm:text-sm"
        placeholder={campaignName}
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
      />
    </div>
  )
}

/* This example requires Tailwind CSS v2.0+ */

export default function ReadOnlyModeBanner({ onClose }) {
  return (
    <>
      <div className="fixed inset-x-0 bottom-0 z-10">
        <div className="bg-gray-600 opacity-90">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-gray-800">
                  <EyeIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">Read-Only mode</span>
                  <span className="hidden md:inline">
                    You are viewing this report in Read-Only mode. If you are
                    the owner, make sure you are logged in with the correct
                    account.
                  </span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ErrorMessage = ({ message }) => {
  return (
    <div className="rounded-md bg-yellow-50 p-4 w-full">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              {message + ' The campaign was stopped and you were not charged.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
