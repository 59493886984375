import {
  auth0Config,
  deleteMFAFactor,
  getLinkToSetUpMFA,
  getMFAFactorsList,
  sendResetPasswordEmail,
} from '../../services'
import { createContext, useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { DangerConfirmationPopup } from '../../components/DangerConfirmationPopup'
import { Header } from './Header'
import { ToasterContent } from '../../components/ToasterContent'
import { TrashIcon } from '@heroicons/react/outline'
import { parseJwt } from '../../utils'
import { toaster } from '../..'
import { useAuth0 } from '@auth0/auth0-react'

/* This example requires Tailwind CSS v2.0+ */

const ContextMFA = createContext()

export const ProfilePage = () => {
  const [mfaToken, setMfaToken] = useState()
  const [mfaItems, setMfaItems] = useState([])
  const [canResetPassword, setCanResetPassword] = useState(false)
  const { isAuthenticated, getAccessTokenWithPopup, user } = useAuth0()
  const [displayDangerPopup, setDisplayDangerPopup] = useState(false)
  const [targetDeleteMFAId, setTargetDeleteMFAId] = useState()

  const handleSentPasswordResetEmail = async () => {
    try {
      await sendResetPasswordEmail(user.email)
      await toaster.notify(
        <ToasterContent
          label={`We've just sent you an email to reset your password.`}
        />,
        { duration: 2000 }
      )
    } catch (e) {
      console.error('Unable to send password reset email.')
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue sending a password reset email. If the error persists, contact support.`}
        />,
        { duration: 4000 }
      )
    }
  }

  const getMFAToken = async (
    tokenMethod = getAccessTokenWithPopup,
    attempt = 1
  ) => {
    if (!isAuthenticated) {
      return
    }
    if (attempt > 2) {
      console.error('Unable to get MFA token with popup.')
      return
    }
    try {
      const mfaAccessToken = await tokenMethod({
        audience: 'https://' + auth0Config.domain + '/mfa/',
        scope: 'enroll read:authenticators remove:authenticators',
      })

      setMfaToken(mfaAccessToken)
      const { sub } = parseJwt(mfaAccessToken)
      const isSocialLogin = sub.split('|')[0].indexOf('auth0') === -1

      if (isSocialLogin) {
        setCanResetPassword(false)
      } else {
        setCanResetPassword(true)
      }
    } catch (e) {
      if (e.message === 'Consent required') {
        console.error('Unable to get MFA token, ', e)
      } else {
        console.error(e)
      }
      setCanResetPassword(true)
    }
  }

  useEffect(() => {
    getMFAToken()
  }, [])

  const handleGetMfaFactors = async () => {
    const factorsList = await getMFAFactorsList(mfaToken)
    if (factorsList.length) {
      setMfaItems(factorsList)
    }
  }

  const openDangerPopup = () => {
    setDisplayDangerPopup(true)
  }

  const handleDeleteMFAItem = async (_id) => {
    try {
      await deleteMFAFactor(_id, mfaToken)
      toaster.notify(<ToasterContent label={`MFA factor deleted.`} />, {
        duration: 4000,
      })
      setDisplayDangerPopup(false)
      setMfaItems(mfaItems.filter(({ id }) => _id !== id))
    } catch (e) {
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue deleting your MFA factor. If the error persists, contact support.`}
        />,
        { duration: 4000 }
      )
    }
  }

  useEffect(() => {
    if (mfaToken && mfaToken.length > 0) {
      handleGetMfaFactors()
    }
  }, [mfaToken])

  return (
    <>
      <Header email={user?.email} />
      <DangerConfirmationPopup
        title="Delete MFA factor"
        mainText="Are you sure you want to delete this MFA factor? This will disable multi-factor authentication on your account."
        display={displayDangerPopup}
        onClose={() => {
          setDisplayDangerPopup(false)
        }}
        onConfirm={() => {
          handleDeleteMFAItem(targetDeleteMFAId)
        }}
      />
      <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              {canResetPassword ? (
                <div className="shadow overflow-hidden border-b sm:rounded-lg">
                  <div className="pb-5 ">
                    <h3 className="text-lg leading-6 font-medium text-white">
                      {'Password'}
                    </h3>
                  </div>
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <ChangePassword
                        sendPasswordResetEmail={handleSentPasswordResetEmail}
                      />
                    </div>
                  </div>
                </div>
              ) : undefined}
              <div>
                <div
                  className={`${canResetPassword ? 'mt-10' : undefined}  pb-5`}
                >
                  <h3
                    className={`text-lg leading-6 font-medium ${
                      canResetPassword ? 'text-black' : 'text-white'
                    }`}
                  >
                    {'Authenticators'}
                  </h3>
                </div>
                <div className="shadow overflow-hidden border-b sm:rounded-lg">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <ContextMFA.Provider
                        value={{ setTargetDeleteMFAId, openDangerPopup }}
                      >
                        <MFAItems mfaItems={mfaItems} />
                      </ContextMFA.Provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

/* This example requires Tailwind CSS v2.0+ */
export default function ChangePassword({ sendPasswordResetEmail }) {
  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Reset Password
      </h3>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <p>
            You will receive an email with a link that will guide you through
            the password resetting action.
          </p>
        </div>
        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button
            onClick={sendPasswordResetEmail}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Reset Password
          </button>
        </div>
      </div>
    </>
  )
}

const MFAItems = ({ mfaItems }) => {
  const auth0 = useContext(AuthContext)
  const handleSetupMfa = async () => {
    try {
      const response = await getLinkToSetUpMFA(auth0)
      if (!response.ticketUrl) {
        throw new Error('Unable to get MFA configuration link')
      }
      window.open(response.ticketUrl, 'Auth0: Setup MFA')
    } catch (e) {
      console.error(e)
    }
  }

  const mfaIsActive = mfaItems.filter((i) => i.active).length > 0

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {`Multi-Factor Authentication (MFA)`}
      </h3>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          {mfaIsActive ? (
            <p>
              You have MFA enabled with OTP and recovery code. To disable MFA or
              reset the recovery code you need to contact support.
            </p>
          ) : (
            <p>Enabling it will redirect you to the MFA configuration page.</p>
          )}
        </div>
        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          {mfaIsActive ? undefined : (
            <button
              onClick={handleSetupMfa}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              Set up MFA
            </button>
          )}
        </div>
      </div>
      {mfaIsActive ? (
        <div className="mt-5 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            {mfaItems.map((item, i) => {
              const { active, authenticator_type, id } = item
              if (!active) {
                // we don't display not active mfa factors
                return undefined
              }
              return (
                <MFALineItem
                  key={id}
                  active={active}
                  authenticatorType={authenticator_type}
                  id={id}
                />
              )
            })}
          </dl>
        </div>
      ) : undefined}
    </>
  )
}

const MFALineItem = ({ active, authenticatorType, id }) => {
  const { openDangerPopup, setTargetDeleteMFAId } = useContext(ContextMFA)
  const getAuthLabel = () => {
    if (authenticatorType === 'otp') {
      return 'One time password (OTP)'
    }
    if (authenticatorType === 'recovery-code') {
      return 'Recovery code'
    }
  }

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">Authenticator Type</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">{`${getAuthLabel()}`}</span>
        {active ? (
          <span className="ml-4 flex-shrink-0 font-medium text-green-600">
            Active
          </span>
        ) : (
          <span className="ml-4 flex-shrink-0 font-medium text-pink-600">
            Inactive
          </span>
        )}
      </dd>
      <button
        className="place-content-center pointer mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-1"
        onClick={() => {
          try {
            setTargetDeleteMFAId(id)
            openDangerPopup()
          } catch (e) {
            console.error('Error deleting MFA, ', e)
          }
        }}
      >
        <TrashIcon className="h-5 w-5" />
      </button>
    </div>
  )
}
