import {
  CalculatorIcon,
  CalendarIcon,
  ChipIcon,
} from '@heroicons/react/outline'
import { useEffect, useState } from 'react'

import { AdminContext } from '../..'
import { AuthContext } from '../../../../App'
import { Header } from './Header'
import { getUserMonthStats } from '../../../../services'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'

export const AdminPageUserMonth = () => {
  const {users} = useContext(AdminContext)
  const auth0 = useContext(AuthContext)
  const [campaigns, setCampaigns] = useState([])
  const [stats, setStats] = useState([])
  const { userId, year, month } = useParams()
  const [userEmail, setUserEmail] = useState('')

  const handleGetMonthData = async () => {
    try {
      const monthData = await getUserMonthStats({ userId, year, month, auth0 })
      const { totalMonthCpuHours, amountFuzzingCampaigns, campaigns } =
        monthData

      const stats = [
        {
          id: 1,
          name: 'Total CPU Hours',
          stat: totalMonthCpuHours,
          icon: ChipIcon,
        },
        {
          id: 2,
          name: 'Total Number of Campaigns',
          stat: campaigns.length,
          icon: CalculatorIcon,
        },
        {
          id: 3,
          name: 'Fuzzing Hours in Month',
          stat: amountFuzzingCampaigns,
          icon: CalendarIcon,
        },
      ]
      setStats(stats)
      setCampaigns(campaigns)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    handleGetMonthData()
  }, [])

  useEffect(() => {
    try {
      const email = users.filter((i) => i.user_id === userId)[0].email
      setUserEmail(email)
    } catch (e) {
      console.error('Unable to get user email, ', e)
    }
  }, [users])

  return (
    <>
      <Header userId={userId} userEmail={userEmail} year={year} month={month} />

      <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <QuickStats stats={stats} />
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b sm:rounded-lg">
                <Month campaigns={campaigns} userId={userId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Month = ({ campaigns, lightText = true, userId }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Campaign
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CPU Hours
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    End Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign, campaignIdx) => {
                  const {
                    campaignId,
                    campaignName,
                    cpuHours,
                    startDate,
                    endDate,
                  } = campaign
                  return (
                    <tr
                      key={campaignId}
                      className={
                        campaignIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {campaignName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {cpuHours}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {`${new Date(startDate).toLocaleString()} `}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {endDate
                          ? `${new Date(endDate).toLocaleString()}`
                          : 'In progress'}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * *  The month view shows the
 * total amount of CPU hours consumed,
 * the amount of fuzzing campaigns, and
 * the cumulative fuzzing duration for a month. It also shows
 */

const QuickStats = ({ stats }) => {
  return (
    <div className="mb-5">
      <h3 className="text-lg leading-6 font-medium text-white">Quick Stats</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16  flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">
                {item.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
