export const PromoCard = () => {
    return (
        <div className="flex-1 w-auto mt-0 md:w-full md:mt-4">
            {/* <div className="grid bg-gradient-to-r from-pink-500 to-indigo-600 rounded-lg shadow-lg p-6"> */}
            <div className="grid 
            bg-gradient-to-r
            from-pink-500
            via-orange-500
            to-indigo-600
            background-animate
             rounded-lg shadow-lg p-6">
                <h2 className="text-white text-2xl font-bold mb-4">Epic Offer!</h2>
                <p className="text-white text-sm mb-4">
                    Get 1 month of our PRO plan absolutely <span className="font-bold">FREE</span>!
                </p>
                <p className="text-white text-sm mb-6">
                    All we ask is a short call to hear your thoughts on our product. Your feedback is valuable to us!
                </p>
                {/* <button className="w-full bg-white py-2 px-4 rounded text-indigo-600 font-bold hover:bg-blue-200 transition duration-300">
            Claim Offer
          </button> */}
                <a href='https://calendar.app.google/K97k3XWgV2t22jR3A' target='_blank' rel='noreferrer'>
                    <button className="w-full border-2 py-3 px-4 rounded text-gray-100 font-bold hover:bg-gray-100 hover:bg-opacity-30 transition duration-300">
                        Claim Offer
                    </button>
                </a>
            </div>
        </div>
    )
}

export const PromoBanner = () => {
    return (<div className='mx-4 sm:mx-6'>
        <div className="flex-col sm:flex-row mb-10 max-w-4xl sm:max-w-5xl lg:max-w-7xl mx-auto px-4 sm:px-6 sm:px-6 lg:px-8 flex bg-gradient-to-r from-pink-500 via-orange-500 to-indigo-600 background-animate rounded-lg shadow-lg p-6">
            <div className="flex-1">
                <h2 className="text-white text-2xl font-bold">Unlock Your Free Month Now!</h2>
                <p className="text-white text-base mt-2">
                    {"Get 1 month of our Builder plan for"} <span className="font-bold">{"FREE"}</span>{" (unlimited 1 hour long campaigns)"}! Fill out a short feedback form and help us improve!
                </p>
            </div>
            <div className="flex-initial m-auto">
                <a href='https://forms.gle/GxsZoQ6CeEJrvxTHA' target='_blank' rel='noreferrer'>
                    <button className="w-full border-2 py-3 px-8 mt-4 sm:mt-0 sm:px-4 rounded text-gray-100 font-bold hover:bg-gray-100 hover:bg-opacity-30 transition duration-300">
                        Claim Offer
                    </button>
                </a>
            </div>
        </div>
    </div>)
}