import 'react-toastify/dist/ReactToastify.css'

import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { ToasterContent } from '../../components/ToasterContent'
import { Transition } from '@headlessui/react'
import { createProject } from '../../services'
import { toast } from 'react-toastify'

// import { toaster } from '../..'

const toaster = {
  notify: (innerComponent, options) => {
    toast(innerComponent, {
      position: 'top-center',
      autoClose: options.duration || 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  },
}

export const NewProjectSlide = ({ show, onClose, inputRef }) => {
  const auth0 = useContext(AuthContext)
  const [projectName, setProjectName] = useState('')

  const handleProjectCreation = async () => {
    try {
      const response = await createProject(projectName, auth0)
      if (response) {
        toaster.notify(
          <ToasterContent label={`Project ${projectName} was created.`} />,
          { duration: 1000 }
        )
        setTimeout(onClose, 500)
      }
    } catch (e) {
      console.error('Error creating project', e)
      toaster.notify(
        <ToasterContent
          error={true}
          label={
            'There was an error creating your Project. Please try again later.'
          }
        />,
        { duration: 1000 }
      )
    }
  }

  const ignoreClick = (e) => {
    e.stopPropagation()
  }
  const onUpdate = (e) => setProjectName(e.target.value)

  const handleSubmit = (e) => {
    e.preventDefault()
    handleProjectCreation()
  }

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      onClose()
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <>
      <div
        onClick={onClose}
        className={`${!show && 'hidden'} fixed inset-0 overflow-hidden z-20`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <section
            className="absolute inset-y-0 pl-16 max-w-full right-0 flex"
            aria-labelledby="slide-over-heading"
          >
            <Transition
              show={show}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <form
                className="w-screen h-full max-w-xs"
                onSubmit={handleSubmit}
              >
                <div onClick={ignoreClick} className="w-screen h-full max-w-xs">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="flex-1 h-0 overflow-y-auto">
                      <div className="pb-12 pt-14 px-4 bg-gray-50 sm:px-6">
                        <div className="flex items-center justify-between">
                          <h2
                            id="slide-over-heading"
                            className="text-lg font-medium text-black"
                          >
                            New Project
                          </h2>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-gray-500">
                            Get started by filling in the information below to
                            create your new project.
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between">
                        <div className="px-4 divide-y divide-gray-200 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project_name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Project name
                              </label>
                              <div className="mt-1">
                                <input
                                  ref={inputRef}
                                  type="text"
                                  value={projectName}
                                  onChange={onUpdate}
                                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                      <button
                        onClick={onClose}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        // onClick={handleProjectCreation}
                        className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Transition>
          </section>
        </div>
      </div>
    </>
  )
}
