const SuccessIcon = () => (
  <svg
    className="block  h-5 w-5 mr-2 text-indigo-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
      clipRule="evenodd"
    ></path>
  </svg>
)

const ErrorIcon = () => (
  <svg
    className="block h-5 w-10 mr-2 text-red-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
      clipRule="evenodd"
    />
  </svg>
)

export const ToasterContent = ({ error = false, label = 'Success' }) => {
  return (
    <div className="flex flex-row justify-center">
      {error ? <ErrorIcon /> : <SuccessIcon />}
      <p>{label}</p>
    </div>
  )
}
