export function Logo(props) {
  return (
    <svg
      width="1312"
      height="390"
      viewBox="0 0 1312 390"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-16 w-44"
    >
      <path
        d="M194.671 0C87.2198 0 0 87.1707 0 194.561C0 301.952 87.2198 389.213 194.671 389.213C302.122 389.213 389.432 302.042 389.432 194.561C389.432 87.0809 302.212 0 194.671 0ZM144.047 301.143C144.047 302.132 143.238 302.941 142.249 302.941H104.304C103.315 302.941 102.506 302.132 102.506 301.143V154.032C102.506 153.043 103.315 152.234 104.304 152.234H142.249C143.238 152.234 144.047 153.043 144.047 154.032V301.143ZM123.187 131.745C109.969 131.745 99.5384 121.949 99.5384 109.008C99.5384 96.0675 109.969 86.2721 123.187 86.2721C136.764 86.2721 147.105 96.0675 147.105 109.008C147.105 121.949 136.764 131.745 123.187 131.745ZM215.981 301.054C215.981 302.042 215.172 302.851 214.183 302.851H176.238C175.249 302.851 174.44 302.042 174.44 301.054V88.0694C174.44 87.0809 175.249 86.2721 176.238 86.2721H214.183C215.172 86.2721 215.981 87.0809 215.981 88.0694V301.054ZM287.825 301.143C287.825 302.132 287.016 302.941 286.027 302.941H248.172C247.183 302.941 246.373 302.132 246.373 301.143V154.032C246.373 153.043 247.183 152.234 248.172 152.234H286.117C287.106 152.234 287.915 153.043 287.915 154.032V301.143H287.825ZM289.893 130.037C289.893 131.026 289.084 131.834 288.095 131.834H246.194C245.204 131.834 244.395 131.026 244.395 130.037V88.0694C244.395 87.0809 245.204 86.2721 246.194 86.2721H288.095C289.084 86.2721 289.893 87.0809 289.893 88.0694V130.037Z"
        fill="white"
      />
      <path
        d="M426.404 322.19C426.404 323.295 427.3 324.19 428.404 324.19H467.334C468.439 324.19 469.334 323.295 469.334 322.19V250.785H529.609C530.714 250.785 531.609 249.89 531.609 248.785V220.455C531.609 219.351 530.714 218.455 529.609 218.455H469.334V168.37H546.039C547.144 168.37 548.039 167.475 548.039 166.37V136.715C548.039 135.611 547.144 134.715 546.039 134.715H428.404C427.3 134.715 426.404 135.611 426.404 136.715V322.19Z"
        fill="white"
      />
      <path
        d="M603.526 327.105C626.316 327.105 640.361 316.505 647.516 302.46V322.19C647.516 323.295 648.411 324.19 649.516 324.19H683.676C684.78 324.19 685.676 323.295 685.676 322.19V187.595C685.676 186.491 684.78 185.595 683.676 185.595H649.516C648.411 185.595 647.516 186.491 647.516 187.595V266.95C647.516 287.355 634.796 297.425 618.631 297.425C602.201 297.425 594.516 288.415 594.516 269.6V187.595C594.516 186.491 593.62 185.595 592.516 185.595H558.356C557.251 185.595 556.356 186.491 556.356 187.595V274.9C556.356 311.205 576.496 327.105 603.526 327.105Z"
        fill="white"
      />
      <path
        d="M698.476 322.19C698.476 323.295 699.372 324.19 700.476 324.19H817.846C818.951 324.19 819.846 323.295 819.846 322.19V296.51C819.846 295.406 818.951 294.51 817.846 294.51H753.596L822.164 190.867C822.381 190.54 822.496 190.156 822.496 189.763V187.595C822.496 186.491 821.601 185.595 820.496 185.595H707.366C706.262 185.595 705.366 186.491 705.366 187.595V213.275C705.366 214.38 706.262 215.275 707.366 215.275H766.846L698.802 319.452C698.59 319.777 698.476 320.157 698.476 320.545V322.19Z"
        fill="white"
      />
      <path
        d="M825.356 322.19C825.356 323.295 826.251 324.19 827.356 324.19H944.726C945.83 324.19 946.726 323.295 946.726 322.19V296.51C946.726 295.406 945.83 294.51 944.726 294.51H880.476L949.044 190.867C949.26 190.54 949.376 190.156 949.376 189.763V187.595C949.376 186.491 948.48 185.595 947.376 185.595H834.246C833.141 185.595 832.246 186.491 832.246 187.595V213.275C832.246 214.38 833.141 215.275 834.246 215.275H893.726L825.681 319.452C825.469 319.777 825.356 320.157 825.356 320.545V322.19Z"
        fill="white"
      />
      <path
        d="M963.957 322.19C963.957 323.295 964.852 324.19 965.957 324.19H1000.12C1001.22 324.19 1002.12 323.295 1002.12 322.19V187.595C1002.12 186.491 1001.22 185.595 1000.12 185.595H965.957C964.852 185.595 963.957 186.491 963.957 187.595V322.19ZM982.772 166.78C995.227 166.78 1004.77 157.77 1004.77 145.845C1004.77 133.92 995.227 124.91 982.772 124.91C970.582 124.91 961.042 133.92 961.042 145.845C961.042 157.77 970.582 166.78 982.772 166.78Z"
        fill="white"
      />
      <path
        d="M1024.18 322.19C1024.18 323.295 1025.07 324.19 1026.18 324.19H1060.6C1061.71 324.19 1062.6 323.295 1062.6 322.19V244.16C1062.6 223.755 1075.06 213.685 1091.49 213.685C1108.45 213.685 1115.87 222.695 1115.87 241.51V322.19C1115.87 323.295 1116.76 324.19 1117.87 324.19H1152.29C1153.4 324.19 1154.29 323.295 1154.29 322.19V235.945C1154.29 198.845 1134.95 182.415 1107.65 182.415C1084.6 182.415 1069.49 193.81 1062.6 207.59V187.595C1062.6 186.491 1061.71 185.595 1060.6 185.595H1026.18C1025.07 185.595 1024.18 186.491 1024.18 187.595V322.19Z"
        fill="white"
      />
      <path
        d="M1238.1 375.335C1283.68 375.335 1310.97 354.4 1311.24 313.325V187.595C1311.24 186.491 1310.34 185.595 1309.24 185.595H1275.08C1273.97 185.595 1273.08 186.491 1273.08 187.595V206.265C1265.13 192.22 1251.61 182.415 1229.09 182.415C1194.64 182.415 1168.14 210.24 1168.14 249.99V251.845C1168.14 292.92 1194.9 318.095 1228.56 318.095C1248.96 318.095 1265.39 305.905 1273.08 292.39V313.325C1273.08 335.055 1261.42 347.245 1238.1 347.245C1219.36 347.245 1210.54 339.99 1207.71 328.714C1207.48 327.786 1206.67 327.105 1205.71 327.105H1171.47C1170.26 327.105 1169.33 328.169 1169.53 329.36C1174 356.75 1194.5 375.335 1238.1 375.335ZM1239.95 289.21C1220.87 289.21 1207.36 274.9 1207.36 251.845V249.725C1207.36 226.935 1219.28 211.565 1240.75 211.565C1261.68 211.565 1274.14 225.875 1274.14 249.46V251.315C1274.14 274.9 1260.09 289.21 1239.95 289.21Z"
        fill="white"
      />
      <path
        d="M434.975 98.365V65.54H439.98C450.9 65.54 455.45 71.585 455.45 81.595V82.115C455.45 92.385 450.445 98.365 440.045 98.365H434.975ZM426.33 105.19H440.305C456.62 105.19 464.485 95.57 464.485 82.05V81.465C464.485 67.945 456.62 58.715 440.435 58.715H426.33V105.19Z"
        fill="white"
      />
      <path d="M476.241 105.19H484.951V58.715H476.241V105.19Z" fill="white" />
      <path
        d="M499.813 105.19H528.283V98.3H508.458V58.715H499.813V105.19Z"
        fill="white"
      />
      <path d="M539.774 105.19H548.484V58.715H539.774V105.19Z" fill="white" />
      <path
        d="M584.211 105.84C597.341 105.84 605.4 98.04 605.4 85.17V80.88H585.186V87.38H596.886C596.626 93.815 593.05 99.08 584.34 99.08C574.59 99.08 569.976 92.255 569.976 82.31V81.79C569.976 71.585 575.24 64.825 584.34 64.825C590.385 64.825 594.546 67.49 595.586 73.665H604.23C602.995 62.55 594.156 58 584.211 58C570.626 58 561.006 67.88 561.006 81.725V82.245C561.006 95.96 569.326 105.84 584.211 105.84Z"
        fill="white"
      />
      <path
        d="M617.646 105.19H647.806V98.365H626.291V84.78H642.671V78.28H626.291V65.54H646.701V58.715H617.646V105.19Z"
        fill="white"
      />
      <path
        d="M659.505 105.19H667.37V69.57L688.3 105.19H697.27V58.715H689.405V92.32L669.84 58.715H659.505V105.19Z"
        fill="white"
      />
      <path
        d="M730.883 105.84C741.933 105.84 749.863 100.185 751.228 89.005H742.778C741.868 95.57 737.318 98.82 730.883 98.82C722.303 98.82 717.298 92.645 717.298 82.18V81.66C717.298 71.26 722.628 64.89 730.818 64.89C737.513 64.89 740.958 68.075 741.933 74.25H750.708C749.668 63.33 741.413 58 730.753 58C717.818 58 708.198 67.75 708.198 81.725V82.245C708.198 96.545 716.323 105.84 730.883 105.84Z"
        fill="white"
      />
      <path
        d="M762.616 105.19H792.776V98.365H771.261V84.78H787.641V78.28H771.261V65.54H791.671V58.715H762.616V105.19Z"
        fill="white"
      />
    </svg>
  )
}
