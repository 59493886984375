import { Redirect, useParams } from 'react-router-dom'
import { deleteProject, getProjectById } from '../../services'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { CAMPAIGN_STATUS } from '../../utils'
import { CampaignsList } from '../../components/CampaignsList'
import { Header } from './Header'
import LoadingPage from '../../LoadingPage'
import { Stats } from './Stats'
import { ToasterContent } from '../../components/ToasterContent'
import { toaster } from '../..'

const { RUNNING, STARTING, IDLE, STOPPED, STOPPING } = CAMPAIGN_STATUS

export const Project = () => {
  const auth0 = useContext(AuthContext)
  const [runningCampaigns, setRunningCampaigns] = useState([])
  const [pastCampaigns, setPastCampaigns] = useState([])
  const [idleCampaigns, setIdleCampaigns] = useState([])
  const [project, setProject] = useState()
  const [loading, setLoading] = useState(true)
  const [redirectToHomepage, setRedirectToHomepage] = useState(false)
  const [timeFuzzed, setTimeFuzzed] = useState(0)
  const [avgVulns, setAvgVulns] = useState(0)
  const { projectId } = useParams()

  const syncData = async () => {
    try {
      const projectResponse = await getProjectById(projectId, auth0)

      let tempRunningCampaigns = []
      let tempPastCampaigns = []
      let tempIdleCampaigns = []
      setProject(projectResponse)
      if (projectResponse.campaigns.length > 0) {
        for (let campaign of projectResponse.campaigns) {
          if (campaign.status === RUNNING) {
            tempRunningCampaigns = [...tempRunningCampaigns, campaign]
          } else if (
            campaign.status === IDLE ||
            campaign.status === STARTING ||
            campaign.status === STOPPING
          ) {
            tempIdleCampaigns = [...tempIdleCampaigns, campaign]
          } else if (campaign.status === STOPPED) {
            tempPastCampaigns = [...tempPastCampaigns, campaign].sort(
              (a, b) => {
                return new Date(b.stoppedAt) - new Date(a.stoppedAt)
              }
            )
          }
        }
        setRunningCampaigns(tempRunningCampaigns)
        setPastCampaigns(tempPastCampaigns)
        setIdleCampaigns(tempIdleCampaigns)

        try {
          // we exclude the idle campaigns from stats calculations because
          // they provide no useful info and would just dilute the average stats
          // for instance the avg vulnerabilities per campaign
          const targetCampaigns = [
            ...tempRunningCampaigns,
            ...tempPastCampaigns,
          ]

          let elapsed=0, totalVulns=0
          
          for (let campaign of targetCampaigns) {
            const { numVulnerabilities, startedAt, stoppedAt } = campaign
            const start = new Date(startedAt)
            const end = new Date(stoppedAt)
            const runTime = (end - start) / 1000
            elapsed = elapsed + runTime
            totalVulns = totalVulns +  Object.values(numVulnerabilities).reduce((a, b) => a + b)
          }
         
          setTimeFuzzed(Math.round(elapsed / 60))
          if (tempPastCampaigns.length > 0) {
            setAvgVulns(Math.round(totalVulns / targetCampaigns.length))
          }
        } catch (e) {
          console.error('Error calculating time spent fuzzing', e)
        }
      }
      setLoading(false)
    } catch (e) {
      console.error('Unable to sync project data', e)
    }
  }

  useEffect(() => {
    syncData().catch()
  }, [])

  const handleDeleteProject = async () => {
    try {
      const response = await deleteProject(projectId, auth0)
      if (response === null) {
        toaster.notify(
          <ToasterContent label={`Your project has been deleted`} />,
          { duration: 1000 }
        )
        setTimeout(() => {
          setRedirectToHomepage(true)
        }, 1000)
      }
    } catch (e) {
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an error deleting your project.`}
        />,
        { duration: 1000 }
      )
    }
  }

  if (redirectToHomepage) {
    return <Redirect to="/" />
  }

  if (loading) {
    return <LoadingPage />
  } else {
    return (
      <>
        <Header projectName={project.name} onDelete={handleDeleteProject} />
        <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Stats
            timeFuzzed={timeFuzzed}
            ongoingCampaigns={runningCampaigns.length}
            avgVulns={avgVulns}
          />
          <CampaignsList
            SectionHeader={CampaignsHeader({ content: `Current Campaigns` })}
            data={[...runningCampaigns, ...idleCampaigns]}
            projectName={project.name}
          />
          <CampaignsList
            SectionHeader={CampaignsHeader({ content: `Past Campaigns` })}
            data={pastCampaigns}
            projectName={project.name}
          />
        </div>
      </>
    )
  }
}

const CampaignsHeader = ({ content }) => (
  <div className="my-8 pb-5 border-b border-gray-200">
    <h3 className="text-lg leading-6 font-medium text-gray-800">{content}</h3>
  </div>
)
