import { CardFrame } from '../tailwind'
import { FaasSourceCodeViewer } from '../FaasSourceCodeViewer'
import React from 'react'
import { SourceFileTitle } from '../tailwind/SourceFileTitle'
import { getKey } from '../../helpers'

const FaaSAnalysesSource = ({
  mythxReport,
  uuid,
  toggleExpandSourceContractCode,
  visibleContracts,
  lineCoverage,
  ignoredIssuesDisplayed,
}) => {
  let isVisible = false
  
  if (mythxReport.input) {
    return (
      <div>
        {mythxReport.sortedFileNames
          // some sources have empty names, we shouldn't display those
          .filter((fileName) => fileName !== '')
          .map((fileName, index) => {
            const sourceCodeContract = mythxReport.sourceFiles[fileName]
            if (!sourceCodeContract) {
              return <></>
            }
            const { sourceFileAvailable } = sourceCodeContract
            if (visibleContracts) {
              let visibleContract = visibleContracts.filter(
                (visCon) => visCon.name === sourceCodeContract.name
              )
              if (visibleContract.length > 0) {
                isVisible = visibleContract[0].visible
              }
            }

            let vulnerabilitySeverities = {
              low: 0,
              medium: 0,
              high: 0,
            }
            const myIssues = mythxReport.getIssuesByFilename(
              sourceCodeContract.name
            )
            myIssues &&
              myIssues.forEach((issue) => {
                let severity = issue.severity
                vulnerabilitySeverities[severity.toLowerCase()] += 1
              })

            const isMainFile =
              mythxReport.input.mainSourceFiles &&
              mythxReport.input.mainSourceFiles.filter(
                (name) => name === fileName
              ).length > 0

            const issuesByFilename = mythxReport.getIssuesByFilename(fileName)
            const numberOfIssues =
              issuesByFilename && issuesByFilename.length
                ? issuesByFilename.length
                : 0

            const handleClickFileTitle = () => {
              toggleExpandSourceContractCode(sourceCodeContract.name)
            }
            return (
              <CardFrame
                key={'source-file__' + fileName}
                dataRole="faas-report__source-file"
              >
                <SourceFileTitle
                  name={fileName}
                  numberOfLines={sourceCodeContract?.sourceLines?.length || 0}
                  numberOfIssues={numberOfIssues}
                  onClick={handleClickFileTitle}
                  expanded={isVisible}
                  isMainFile={isMainFile}
                />
                <div
                  data-id={`source-code-block-${index}`}
                  className={`source-code-block sm:rounded-lg ${
                    isVisible ? 'mt-6' : undefined
                  }`}
                >
                  { sourceFileAvailable && visibleContracts && (
                    <FaasSourceCodeViewer
                      filename={sourceCodeContract.name}
                      visible={isVisible}
                      codeSample={sourceCodeContract.sourceLines}
                      lineCoverage={lineCoverage[sourceCodeContract.name]}
                      ignoredIssuesDisplayed={ignoredIssuesDisplayed}
                    />
                  )}
                  {!sourceFileAvailable &&
                    visibleContracts &&
                    isVisible &&
                    mythxReport.getIssuesByFilename(fileName).map((v) => {
                      return (
                        <div
                          data-role="faas-report__vulnerability-block"
                          key={getKey()}
                          className={
                            'source-error source-error--' +
                            v.severity.toLocaleLowerCase() +
                            ' '
                          }
                        >
                          <p>
                            <i className="fa pe-7s-shield fa-2x analysedfile__icon analysedfile__icon--issues" />
                            <span
                              className={`vulnerability-severity vulnerability-severity-${v.severity.toLocaleLowerCase()}`}
                            >
                              {v.severity}
                            </span>
                            <div />
                            <span className="source-swc-number">
                              {v.swcID + ' |'}{' '}
                            </span>
                            <span className="source-swc-title">
                              {v.swcTitle}
                            </span>
                          </p>
                          <p className="source-error-description">
                            {v.description.tail}
                          </p>
                        </div>
                      )
                    })}
                </div>
              </CardFrame>
            )
          })}
      </div>
    )
  }
  return null
}

export default React.memo(FaaSAnalysesSource, (prev, next) => {
  return (
    prev.mythxReport === next.mythxReport &&
    prev.uuid === next.uuid &&
    prev.visibleContracts === next.visibleContracts &&
    prev.lineCoverage === next.lineCoverage &&
    prev.ignoredIssuesDisplayed === next.ignoredIssuesDisplayed
  )
})
