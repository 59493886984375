import { createContext, useContext, useEffect, useState } from 'react'

import { AdminContext } from '.'
import { CampaignsList } from './AdminCampaignsList'
import { Header } from './Header'
import { SearchIcon } from '@heroicons/react/solid'
import { UserList } from './AdminUsersList'

const TABS = {
  USERS: 'users',
  CAMPAIGNS: 'campaigns',
}

const tabsContextInitValue = {
  tabValue: TABS.USERS,
  TABS,
}

export const TabsContext = createContext(tabsContextInitValue)

export const AdminPage = () => {
  const {users, campaigns} = useContext(AdminContext)
  const [searchText, setSearchText] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users)
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns)
  const [tab, setTab] = useState(TABS.USERS)

  useEffect(() => {
    setFilteredUsers(users)
    setFilteredCampaigns(campaigns)
  }, [users, campaigns])

  useEffect(() => {
    const filteredUsers = users.filter(({ email }) => {
      return email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    })
    const filteredCampaigns = campaigns.filter(({ owner_email }) => {
      return owner_email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    })
    setFilteredUsers(filteredUsers)
    setFilteredCampaigns(filteredCampaigns)
  }, [searchText])

  return (
    <>
      <TabsContext.Provider
        value={{
          tabValue: tab,
          setTab: setTab,
          TABS,
        }}
      >
        <Header tab={tab} setTab={setTab} />
      </TabsContext.Provider>
      <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b sm:rounded-lg">
                <HeadingWithSearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                {tab === TABS.USERS ? (
                  <UserList users={filteredUsers} />
                ) : (
                  <CampaignsList campaigns={filteredCampaigns} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HeadingWithSearchBar = ({ searchText, setSearchText }) => {
  return (
    <div className="pb-5  sm:flex sm:items-center sm:justify-between">
      <h3 className="text-xl leading-6 font-medium text-white">Users</h3>
      <div className="mt-3 sm:mt-0 sm:ml-4">
        <label htmlFor="mobile-search-candidate" className="sr-only">
          Search
        </label>
        <label htmlFor="desktop-search-candidate" className="sr-only">
          Search
        </label>
        <div className="flex rounded-md shadow-sm">
          <div className="relative flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="mobile-search-candidate"
              id="mobile-search-candidate"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:hidden border-gray-300"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <input
              type="text"
              name="desktop-search-candidate"
              id="desktop-search-candidate"
              className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
              placeholder="Search by email"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}