import {
  ExclamationCircleIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react'
import Fuse from 'fuse.js'

export const PropertiesTable = ({
  issuesReport,
  toggleExpandSourceContractCode,
  displayNotDetected,
  propertiesSearch,
  filters = {
    displayPassed: true,
    displayFailed: true,
    displayPartiallyChecked: true,
  }
}) => {
  const { displayPassed, displayFailed, displayPartiallyChecked } = filters

  const scrollToLine = (filename, line) => {
    toggleExpandSourceContractCode(filename, true)
    setTimeout(() => {
      const selector = `[data-id='line-${filename}-${line}']`
      const el = document.querySelector(selector)
      el && el.scrollIntoView({ behavior: 'smooth' })
    }, 50)
  }

  const searchOptions = {
    includeScore: true,
    keys: ['name', 'description', "sourceFile"],
    threshold: 0.3,
  }

  let propertiesToDisplay = issuesReport.properties
    // here we filter the properties based on the 
    // displayPassed, displayFailed, displayPartiallyChecked values.  
    .filter((property) => {
      if (property.violated === true && displayFailed) {
        return true
      } else if (property.violated === false && displayPassed) {
        return true
      } else if (property.skip === true && displayPartiallyChecked) {
        return true
      } else {
        return false
      }
    })
    // sort properties by skip status
    // if skip is true, it will be at the end of the list
    .sort((a, b) => {
      if (a.violated === b.violated) {
        if (a.skip === b.skip) {
          return 0
        } else if (a.skip === true) {
          return 1
        } else {
          return -1
        }
      } else if (a.violated === true) {
        return -1
      } else {
        return 1
      }
    })


  let filteredProperties = []
  if (propertiesSearch && propertiesSearch.length > 0) {
    // we don't want to filter if the search is empty to save on performance
    const fuse = new Fuse(issuesReport.properties, searchOptions)
    filteredProperties = fuse.search(propertiesSearch).map((result) => result.item)
    propertiesToDisplay = filteredProperties
  }


  return (
    <div data-role="faas-report__properties-table" className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          data-role="faas-report__properties-table--inline-wrapper"
          className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
        >
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    colSpan={2}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Property
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Checked
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {
                  propertiesToDisplay
                    .map((property) => {
                      if (
                        displayNotDetected &&
                        property.covered &&
                        !property.violated
                      ) {
                        return undefined
                      }
                      return (
                        <Fragment
                          key={
                            'property' +
                            property.id +
                            property?.name +
                            property.sourceFile
                          }
                        >
                          <PropertyRow
                            key={property.id}
                            name={
                              property.name ? property.name : 'Unnamed property'
                            }
                            contract={property.contractName}
                            checked={property.covered}
                            violated={property.violated}
                            skip={property?.skip || false}
                          />
                          {property.issues &&
                            property.issues.map((issue, i) => {
                              if (!issue) {
                                return <Fragment key={`no-issue--${i}`} />
                              }
                              return (
                                <Row
                                  key={
                                    'property' +
                                    property.id +
                                    property?.name +
                                    property.sourceFile +
                                    '_issue_' +
                                    issue.uuid
                                  }
                                  scrollToLine={scrollToLine}
                                  name={
                                    issue?.description?.head ||
                                    'Name unavailable'
                                  }
                                  file={
                                    issue?.sourceCode?.name || 'File available'
                                  }
                                  location={[
                                    issue.getStartingLine(),
                                    issue.getStartingColumn(),
                                  ]}
                                />
                              )
                            })}
                        </Fragment>
                      )
                    })
                }
                {issuesReport.issuesWithoutProperties.length > 0 ? (
                  <>
                    <PropertyRow
                      key={'issues-without-property'}
                      name={'Issues without properties'}
                      contract={'Other'}
                      checked={true}
                      violated={true}
                      skip={false}
                    />
                    {issuesReport.issuesWithoutProperties.map((issue, i) => {
                      return (
                        <Row
                          key={'no-property-issue_' + i}
                          scrollToLine={scrollToLine}
                          name={issue.description.head}
                          file={issue.sourceCode.name}
                          location={[
                            issue.getStartingLine(),
                            issue.getStartingColumn(),
                          ]}
                        />
                      )
                    })}
                  </>
                ) : undefined}
              </tbody>
              <tfoot className="border-none" />
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const Row = ({
  scrollToLine,
  name,
  file,
  location,
}) => {
  const [line] = location

  return (
    <tr className={`${'bg-gray-50'}`}>
      <td
        colSpan={1}
        className="px-6 pl-12 py-2 whitespace-nowrap text-sm font-medium text-gray-900"
      >
        <ExclamationCircleIcon className="mr-2 inline h-5 w-5 text-gray-400" />
        <span>{`${name}`}</span>
      </td>
      <td
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
        colSpan={2}
      >
        {file.length && file.length >= 60 ? (
          <p dir="rtl" className="max-w-md overflow-x-auto hide-scrollbar">
            {file}
          </p>
        ) : (
          file
        )}
      </td>

      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium">
        <div className="flex justify-center">
          <button
            data-role="faas-report__properties-table-location-btn"
            onClick={() => {
              scrollToLine(file, line)
            }}
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <LinkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            {`Line ${line}`}
          </button>
        </div>
      </td>
    </tr>
  )
}

const PropertyRow = ({ name, checked, violated, contract, skip = false }) => {
  const [truncate, setTruncate] = useState(true)

  const toggleTruncate = () => {
    setTruncate(!truncate)
  }

  return (
    <tr className={`py-4 bg-white`}>
      {/* <tr className={`py-4 bg-gray-50 border-t border-gray-300`}> */}
      <td
        className={`max-w-sm px-6 py-4 text-sm font-medium text-gray-900 ${truncate ? 'truncate' : 'break-words'
          }`}
        colSpan={2}
        onClick={toggleTruncate}
      >
        {`(${contract}) ${name}`}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        {checked ? (
          <svg
            className="block m-auto h-5 w-5 text-indigo-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="block m-auto h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex justify-center">
          <StatusBadge violated={violated} skip={skip} />
        </div>
      </td>
    </tr>
  )
}

const StatusBadge = ({ violated, skip }) => {
  if (skip) {
    // For skipped property checks we link to the docs, where we explain why we skip them.
    // The explanation was too long to fit in a tooltip.
    return (
      <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
        Partially-checked{' '}
        <a
          href="https://fuzzing-docs.diligence.tools/getting-started/fuzzing-foundry-projects#partial-property-checks"
          target="_blank"
          rel="noreferrer"
        >
          <QuestionMarkCircleIcon
            className=" ml-1 h-4 w-4 text-gray-500"
            aria-hidden="true"
          />
        </a>
      </span>
    )
  } else {
    switch (violated) {
      case false:
        return (
          <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
            Passed
          </span>
        )
      case true:
        return (
          <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800">
            Failed
          </span>
        )
      default:
        return (
          <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
            N/A
          </span>
        )
    }
  }
}
