import { ClockIcon } from '@heroicons/react/solid'

export const QuickStatistics = ({
  isRunning,
  testCasesPerSecond,
  totalCodeCoverage,
  duration,
  residualRisk,
  timeLimit,
}) => (
  <div>
    <h3 className="text-lg leading-6 font-medium text-white">
      Quick Statistics
    </h3>
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">
            Test Cases per Second
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {isRunning ? testCasesPerSecond : '0'}
          </dd>
        </div>
      </div>

      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">
            Total Code Coverage
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {totalCodeCoverage}
          </dd>
        </div>
      </div>

      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">
            <div className="flex justify-between">
              <span>Duration</span>
              {timeLimit ? <span className='flex'>
                <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" />{' '}
                {`Max ${timeLimit}`}
              </span> : undefined}
            </div>
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {duration}
          </dd>
        </div>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">
            Residual Risk
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {residualRisk ? `${residualRisk} %` : '100%'}
          </dd>
        </div>
      </div>
    </dl>
  </div>
)
