import { Redirect, Route, Switch } from 'react-router-dom'
import { getAdminActiveCampaigns, getUsers } from '../../services'

import { AdminPage } from './AdminPage'
import { AdminPageUser } from './User'
import { AdminPageUserMonth } from './User/Month'
import { AuthContext } from '../../App'
import { createContext } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

export const AdminContext = createContext()

export const AdminRouter = ({ match }) => {
  const auth0 = useContext(AuthContext)
  const [users, setUsers] = useState([])
  const [campaigns, setCampaigns] = useState([])

  const handleGetUsers = async () => {
    try {
      const users = await getUsers(auth0)
      setUsers(users)
    } catch (e) {
      console.error(e)
    }
  }

  const handleGetCampaigns = async () => {
    try {
      const campaigns = await getAdminActiveCampaigns(auth0)
      setCampaigns(campaigns)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    handleGetUsers()
    handleGetCampaigns()
  }, [])


  return (
    <AdminContext.Provider value={{users,campaigns}}>
      <Switch>
        <Route
          path="/admin/:userId/:year/:month"
          component={AdminPageUserMonth}
        />
        <Route path="/admin/:userId/:year">
          <Redirect to="/admin" />
        </Route>
        <Route path="/admin/:userId" component={AdminPageUser} />
        <Route component={AdminPage} />
      </Switch>
    </AdminContext.Provider>
  )
}
