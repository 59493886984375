import React from 'react';
import { PropertiesTitleWithSearch } from './PropertiesTitleWithSearch';
import { PropertiesTable } from './PropertiesTable';
import { useState } from 'react';
export const PropertiesTableWrapper = ({
    displayNotDetected,
    issuesReport,
    toggleExpandSourceContractCode
}) => {

    const [propertiesSearch, setPropertiesSearch] = useState('')
    const [displayPassed, setDisplayPassed] = useState(true)
    const [displayFailed, setDisplayFailed] = useState(true)
    const [displayPartiallyChecked, setDisplayPartiallyChecked] = useState(true)

    const selectedClasses = "outline outline-2 outline-indigo-700"

    const StatusFilters = () => (<div className='flex space-x-4'>
        <span className={`${displayPassed ? selectedClasses : undefined} cursor-pointer m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800`}
            onClick={() => setDisplayPassed(!displayPassed)}>
            Passed
        </span>

        <span className={`${displayFailed ? selectedClasses : undefined} cursor-pointer  m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800`}
            onClick={() => setDisplayFailed(!displayFailed)}>
            Failed
        </span>

        <span className={`${displayPartiallyChecked ? selectedClasses : undefined} cursor-pointer m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800`}
            onClick={() => setDisplayPartiallyChecked(!displayPartiallyChecked)}>
            Partially-checked
        </span>
    </div>)


    return (<>
        <PropertiesTitleWithSearch
            propertiesSearch={propertiesSearch}
            setPropertiesSearch={setPropertiesSearch}
            StatusFiltersComponent={StatusFilters}
        />

        <PropertiesTable
            propertiesSearch={propertiesSearch}
            displayNotDetected={displayNotDetected}
            issuesReport={issuesReport}
            toggleExpandSourceContractCode={toggleExpandSourceContractCode}
            filters={{displayPassed, displayFailed, displayPartiallyChecked}}
        />
    </>)
}