import StartPage from '../StartPage'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

export const LogoutPage = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: "https://consensys.net/diligence/fuzzing" })
  }, [])

  return <StartPage />
}
