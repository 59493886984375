import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useState } from 'react'

import { CheckIcon, } from '@heroicons/react/outline'
import { GettingStartedContext } from '../App'

const getStartedLinks = {
  row1: [
    {
      title: '🛠️ Fuzzing Foundry Projects',
      href: 'https://fuzzing-docs.diligence.tools/getting-started/fuzzing-foundry-projects',
    },
    // {
    //   title: '📊 Understanding Reports',
    //   href: 'https://fuzzing-docs.diligence.tools/general/reports/coverage',
    // },

  ],
  row2: [

    {
      title: '🍎 Fuzzing non-Foundry Projects',
      href: 'https://fuzzing-docs.diligence.tools/getting-started/fuzzing-non-foundry-projects',
    },
  ],
}

export const WelcomeModal = () => {

  const { open, setOpen } = useContext(GettingStartedContext)



  const handleClose = () => {
    setOpen(false)
    window.localStorage.setItem('welcomeModalSkipped', 'true')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-8">
                <div>
                  <div className="mx-auto flex  items-center justify-center rounded-full ">
                    <p className=" text-6xl">🎉</p>
                  </div>
                  <div className="mt-3 text-base text-left sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className=" text-lg text-center font-semibold leading-6 text-gray-900"
                    >
                      Welcome to Diligence Fuzzing!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className=" text-gray-500 text-justify">
                        Congratulations on setting up your fuzzing account.
                        Check out your email for our day-1 fuzzing quick start,
                        it'll get you started in no time!
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className=" text-gray-500 text-justify mb-4">
                        We've also put together a set of guides that cover
                        everything from setting up your workspace to advanced
                        fuzzing techniques. We recommend starting with the "All About Fuzzing":
                      </p>
                    </div>
                    <div>
                      <li key={"proj1"} className="col-span-1 flex rounded-md shadow-sm">
                        <div
                          className={
                            'bg-indigo-600 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md  font-medium text-white'}
                        >
                          <RocketLaunchIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">

                          <div className="flex-1 truncate px-4 py-2 text-sm">
                            <a href={"https://fuzzing-docs.diligence.tools/getting-started/quickstart"} className=" cursor-pointer font-medium text-gray-900 hover:text-gray-600" target='_blank' rel="noreferrer">
                              {"All about Fuzzing"}

                              <p className="text-gray-500">{"Getting Up and Running"}</p>
                            </a>
                          </div>

                          <div className="flex-shrink-0 pr-2">
                            <button
                              type="button"
                              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <span className="sr-only">Open options</span>
                              {/* <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" /> */}
                            </button>
                          </div>
                        </div>
                      </li>
                      <div className="mt-2">
                        <p className=" text-gray-500 text-justify my-4">
                          We also recommend checking out these guides:
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mb-8 flex flex-col sm:flex-row place-content-around">
                      <ul className=" text-gray-500">
                        {getStartedLinks.row1.map(({ title, href }) => (
                          <a
                            key={title + href}
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li className="mt-2 underline">{title}</li>
                          </a>
                        ))}
                      </ul>
                      <ul className=" text-gray-500">
                        {getStartedLinks.row2.map(({ title, href }) => (
                          <a
                            key={title + href}
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li className="mt-2 underline">{title}</li>
                          </a>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


const RocketLaunchIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
</svg>)
