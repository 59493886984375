import { AuthContext } from '../../App'
import { createCustomerPortalSession } from '../../services'
import { useContext } from 'react'

export const SubscriptionPanel = ({activeSubscription}) => {
  const auth0 = useContext(AuthContext)  
  // const [activeSubscription, setActiveSubscription] = useState()
  
  const handleRedirectToStripePortal = async () => {
    try {
      const { url } = await createCustomerPortalSession(auth0)
      if (url) {
        window.location.href = url
      }
    } catch (e) {
      console.error('Error redirecting to stripe customer portal.', e)
    }
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className=" border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Subscription Plan
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
              {activeSubscription?.plan?.metadata?.product}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow capitalize">
                {activeSubscription?.status}
                <span className='lowercase'>
                  {activeSubscription?.cancel_at_period_end
                    ? ' (cancellation scheduled)'
                    : undefined}
                </span>
              </span>
              {activeSubscription?.status === 'active' ? (
                <span className="ml-4 flex-shrink-0">
                  <button
                    onClick={handleRedirectToStripePortal}
                    type="button"
                    className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Manage subscription
                  </button>
                </span>
              ) : undefined}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
