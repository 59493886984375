import React from 'react'

export const getKey = () => {
  return Math.floor(Math.random() * 1000000000)
}

export const OtherAnalysisInfo = ({ issuesReport }) => {
  const hasInfo = issuesReport.processedIssues.filter(({ severity }) => severity === 'info').length > 0
  if (!hasInfo) {
    return <></>
  }
  return (<><h3 className='report__subtitle'>Other info </h3>
      {issuesReport.processedIssues.map(issue => {
        if (issue.severity !== 'info') return<></>
        return (
          <div key={getKey()} className={`vulnerability__container vulnerability__container--${issue.severity.toLocaleLowerCase()}`}>
            <i className='fa pe-7s-shield fa-2x analysedfile__icon analysedfile__icon--issues' />
            <p className={`vulnerability-severity vulnerability-severity-${issue.severity.toLocaleLowerCase()}`}>{issue.severity}</p>
            <p className='vulnerability__classification'><b>{issue.swcID}</b> {issue.swcTitle} | <i>{issue.description.head}</i></p>
            <p className='vulnerability__description'>{`Warning: Not all results are displayed. Upgrade to MythX Pro to see the full list of smart contract weaknesses detected. For more information, please see https://mythx.io/plans`}</p>
          </div>
        )
      })}</>)
}
