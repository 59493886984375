import { getProjectsList, updateCampaign } from '../../services'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { ToasterContent } from '../../components/ToasterContent'
import { Transition } from '@headlessui/react'
import { toaster } from '../..'

export const SettingsSlide = ({ show, onClose, campaignMetadata }) => {
  const auth0 = useContext(AuthContext)
  const [projectsList, setProjectsList] = useState([])
  const [campaignName, setCampaignName] = useState(campaignMetadata.name)
  const [projectId, setProjectId] = useState(campaignMetadata?.project?.id)

  const onSelectProject = (id) => {
    setProjectId(id)
  }

  const fetchData = async () => {
    try {
      const projectsResponse = await getProjectsList(auth0)
      if (projectsResponse) {
        setProjectsList(projectsResponse)
      }
    } catch (e) {
      console.error('Error getting projects list')
    }
  }

  useEffect(() => {
    fetchData().catch()
  }, [])

  const ignoreClick = (e) => {
    e.stopPropagation()
  }

  const handleUpdateCampaign = async () => {
    try {
      const response = await updateCampaign(
        campaignMetadata.id,
        campaignName,
        projectId,
        auth0
      )
      if (response) {
        toaster.notify(
          <ToasterContent label={`Your campaign details have been updated.`} />,
          { duration: 1000 }
        )
        setTimeout(() => onClose(true), 500)
      }
    } catch (e) {
      toaster.notify(
        <ToasterContent
          error={true}
          label={`There was an issue updating your campaign. Please try again.`}
        />,
        { duration: 1000 }
      )
    }
  }

  return (
    <>
      <div
        onClick={onClose}
        className={`${!show && 'hidden'} fixed inset-0 overflow-hidden z-20`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <section
            className="absolute inset-y-0 pl-16 max-w-full right-0 flex"
            aria-labelledby="slide-over-heading"
          >
            <Transition
              show={show}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div onClick={ignoreClick} className="w-screen h-full max-w-2xl">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="pb-12 pt-14 px-4 bg-gray-50 sm:px-6">
                      <div className="flex items-center justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-medium text-black"
                        >
                          Campaign Settings
                        </h2>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            onClick={onClose}
                            className="bg-gray-50 rounded-md text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
                          >
                            <span className="sr-only">Close panel</span>
                            {/* Heroicon name: outline/x */}
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Change the campaign name and Project.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              htmlFor="project_name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Campaign name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                value={campaignName}
                                onChange={(e) => {
                                  setCampaignName(e.target.value)
                                }}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              htmlFor="project_name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Select Project
                            </label>
                            <div className="mt-1">
                              <fieldset>
                                <legend
                                  id="radiogroup-label"
                                  className="sr-only"
                                >
                                  Project name
                                </legend>
                                <ul
                                  className="space-y-4"
                                  role="radiogroup"
                                  aria-labelledby="radiogroup-label"
                                >
                                  {projectsList.map((item) => {
                                    const onClick = () => {
                                      onSelectProject(item.id)
                                    }
                                    return (
                                      <LineItem
                                        key={'project_item__' + item.id}
                                        id={item.id}
                                        projectName={item.name}
                                        numberOfCampaigns={
                                          item?.campaings?.length
                                        }
                                        creationDate={item.createdAt}
                                        selected={projectId === item.id}
                                        onClick={onClick}
                                      />
                                    )
                                  })}
                                </ul>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      onClick={onClose}
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={
                        campaignName === campaignMetadata.name &&
                        projectId === campaignMetadata.project
                      }
                      onClick={handleUpdateCampaign}
                      className="disabled:opacity-50 ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </Transition>
          </section>
        </div>
      </div>
    </>
  )
}

const LineItem = ({
  projectName,
  numberOfCampaigns,
  creationDate,
  selected,
  onClick,
  id,
}) => {
  return (
    <li
      onClick={onClick}
      id="radiogroup-option-0"
      tabIndex="0"
      role="radio"
      aria-checked="true"
      className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <div className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
        <div className="flex items-center">
          <div className="text-sm">
            <p className="font-medium text-gray-900">{projectName}</p>
            <div className="text-gray-500">
              {numberOfCampaigns ? (
                <p className="sm:inline">{numberOfCampaigns} campaigns</p>
              ) : undefined}
              <span className="hidden sm:inline sm:mx-1" aria-hidden="true">
                &middot;
              </span>
              {creationDate ? (
                <p className="sm:inline">
                  Created on {new Date(creationDate).toLocaleDateString()}
                </p>
              ) : undefined}
            </div>
          </div>
        </div>
      </div>
      {/* On: "border-indigo-500", Off: "border-transparent" */}
      <div
        className={`${
          selected ? 'border-indigo-500' : 'border-transparent'
        } absolute inset-0 rounded-lg border-2 pointer-events-none`}
        aria-hidden="true"
      ></div>
    </li>
  )
}
