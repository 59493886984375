import { IssueBlock } from './IssueBlock'
import React from 'react'
import { getIdByBlockIndex } from '../../helpers'

export const IssueBlocks = ({
  issues,
  currentLineNumber,
}) => {
  return issues.map((issue, j) => {
    const { description, extra, location, ignored } =
      issue
    let issueId = ''
    try {
      const fileIndex = location.sourceMap.split(':')[2]
      const fileName = location.sourceList[fileIndex]
      // increasing by one because we don't show line 0
      issueId = `${fileName}-${currentLineNumber + 1}`
    } catch (e) {
      // console.error('Unable to create issueId')
      issueId = `${location.sourceMap.split(':')[2]}-${currentLineNumber + 1}`
    }
    
    const blockIdentifier = getIdByBlockIndex({
      type: 'block',
      lineNumber: currentLineNumber,
      index: j,
    })
    return (
      <IssueBlock
        id={issueId}
        key={blockIdentifier}
        head={description && description.head}
        tail={description && description.tail}
        testCases={extra && extra.testCases}
        debugInfo={extra && extra.debugInfo}
        currentBlock={j}
        currentLine={currentLineNumber}
        ignored={ignored}
      />
    )
  })
}


