import './index.css'

import { ToastContainer, toast } from 'react-toastify'

import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import ReactDOM from 'react-dom'
import apm from './rum'
import { auth0Config } from './services'
import reportWebVitals from './reportWebVitals'

export const toaster = {
  notify: (innerComponent, options) => {
    toast(innerComponent, {
      position: 'top-center',
      autoClose: options.duration || 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  },
}

// `cacheLocation={"localstorage"}` and `useRefreshTokens={true}` options will allow us to cache the tokens and bypass
// ITP (Intelligent Tracking Prevention) of Safari, Brave and Incognito Modes of Firefox and Chrome. ITP blocks the
// 3rd-party cookies which led to broken login.
apm.setInitialPageLoadName('DashboardMainPage')
ReactDOM.render(
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    redirectUri={auth0Config.redirectUri}
    scope={auth0Config.scope}
    audience={auth0Config.audience}
    cacheLocation={'localstorage'}
    useRefreshTokens={true}
  >
    <React.StrictMode>
      <ToastContainer
        position="top-right"
        autoClose={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <App />
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
