import { getCampaignsList, getProjectsList } from '../../services'
import { useContext, useEffect, useRef, useState } from 'react'

import { AuthContext } from '../../App'
import { CAMPAIGN_STATUS } from '../../utils'
import { CampaignsList, CampaignStartGuide } from '../../components/CampaignsList'
import { NewProjectSlide } from './NewProjectSlide'
import { ProjectsList } from './ProjectsList'
import { RecentCampaigns } from './RecentCampaigns'
import { WelcomeModal } from '../../components/WelcomeModal'
import { useHistory } from 'react-router-dom'
import { PromoCard } from '../../FaasReport/components/tailwind/PromoCards'

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

const TopCampaignsHeader = ({ content }) => (
  <div className="pb-5 ">
    <h3 className="text-lg leading-6 font-medium text-white">{content}</h3>
  </div>
)

export const Dashboard = () => {
  const auth0 = useContext(AuthContext)

  const [showNewProjectSlide, setShowNewProjectSlide] = useState(false)
  const [runningCampaigns, setRunningCampaigns] = useState([])
  const [idleCampaigns, setIdleCampaigns] = useState([])
  const [recentCampaigns, setRecentCampaigns] = useState([])
  const [projectsList, setProjectsList] = useState([])
  const [inputRef, setInputFocus] = useFocus()

  

  const syncProjects = async () => {
    try {
      const projectsListResponse = await getProjectsList(auth0)
      setProjectsList(projectsListResponse)
    } catch (e) {
      console.error('Unable to sync projects data', e)
    }
  }

  const syncCampaigns = async () => {
    const { IDLE, RUNNING, STOPPING, STARTING } = CAMPAIGN_STATUS
    try {
      const campaignsResponse = await getCampaignsList(auth0)

      setIdleCampaigns(
        campaignsResponse.filter(
          (c) =>
            c.status === IDLE || c.status === STARTING || c.status === STOPPING
        )
      )
      setRunningCampaigns(campaignsResponse.filter((c) => c.status === RUNNING))
      setRecentCampaigns(campaignsResponse.slice(0, 5))
    } catch (e) {
      console.error('Unable to sync campaigns data', e)
    }
  }

  const syncData = async () => {
    try {
      const projectsPromise = syncProjects()
      const campaignsPromise = syncCampaigns()
      Promise.all([projectsPromise, campaignsPromise])
    } catch (e) {
      console.error('Unable to sync data', e)
    }
  }

  useEffect(() => {
    syncData()
    const interval = setInterval(syncCampaigns, 10 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const onCloseNewProjectSlide = () => {
    setShowNewProjectSlide(false)
    syncData()
  }
  const onOpenNewProjectSlide = () => {
    setShowNewProjectSlide(true)
    setTimeout(() => {
      setInputFocus()
    }, 500)
  }

  return (
    <>
      <WelcomeModal />
      <div className="bg-gray-800 pb-16 pt-10 mb-10"></div>
      <main className="-mt-32">
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
          <div className="lg:col-start-1 lg:col-span-3">
            {runningCampaigns.length + idleCampaigns.length > 0 ? <CampaignsList
              SectionHeader={TopCampaignsHeader({
                content: `Current Campaigns`,
              })}
              data={[...runningCampaigns, ...idleCampaigns]}
            /> : <CampaignStartGuide
              SectionHeader={TopCampaignsHeader({
                content: `Campaigns`,
              })}
              data={[...runningCampaigns, ...idleCampaigns]}
            />}
            <div className="my-8 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Projects
              </h3>
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <button
                  onClick={onOpenNewProjectSlide}
                  name="add-project-button"
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <svg
                    className="-ml-2 w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="{2}"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  Add Project
                </button>
              </div>
            </div>
            <ProjectsList data={projectsList} />
            <NewProjectSlide
              show={showNewProjectSlide}
              onClose={onCloseNewProjectSlide}
              inputRef={inputRef}
            />
          </div>
          <div className="lg:col-start-4 lg:col-span-1 self-start h-full">
            <div className='grid grid-rows-[1fr 2fr] h-full'>
              <div>
                <RecentCampaigns data={recentCampaigns.slice(0, 2)} />
              </div>
              {/* <div className='place-self-end'>
                <PromoCard />
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

