import { CAMPAIGN_STATUS } from '../../utils'
import { EmptyList } from '../../components/EmptyList'
import { Jdenticon } from '../../components/Jdenticon'
import { Link } from 'react-router-dom'
import { helpers } from '../../FaasReport'

const { RUNNING, STARTING } = CAMPAIGN_STATUS

export const ProjectsList = ({ data }) => {
  return (
    <>
      <div className="space-y-6  bg-white shadow overflow-hidden sm:rounded-md  ">
        <ul name="projects-list" className="divide-y divide-gray-200">
          {data.length > 0 ? (
            data.map((item) => {
              const isCurrentlyRunning =
                item.campaigns.filter(
                  (i) => i.status === RUNNING || i.status === STARTING
                ).length > 0
              const { campaigns } = item
              return (
                <ListItem
                  key={item.name + item.createdAt}
                  projectId={item.id}
                  projectName={item.name}
                  numOfCampaigns={item.campaigns.length}
                  dateCreated={item.createdAt}
                  dateLastCampaign={
                    campaigns[campaigns.length - 1] &&
                    campaigns[campaigns.length - 1].submittedAt
                  }
                  isCurrentlyRunning={isCurrentlyRunning}
                />
              )
            })
          ) : (
            <EmptyList />
          )}
        </ul>
        {/* <ListPagination currentPage={2} totalItems={231} itemsPerPage={10} /> */}
      </div>
    </>
  )
}

const LastUpdate = ({ isCurrentlyRunning, dateLastCampaign }) => {
  let content
  if (dateLastCampaign) {
    if (isCurrentlyRunning) {
      content = `Running now`
    } else {
      content = `Last campaign on ${helpers.timeSince(
        new Date(dateLastCampaign)
      )} ago`
    }
  } else {
    content = `No campaigns yet.`
  }

  return <p className="text-sm text-gray-900">{content}</p>
}

const ListItem = ({
  projectId,
  projectName,
  numOfCampaigns,
  dateCreated,
  dateLastCampaign,
  isCurrentlyRunning,
}) => {
  return (
    <li name="projects-list-item">
      <Link to={`/projects/${projectId}`}>
        <span className="block hover:bg-gray-50">
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="flex-shrink-0">
                <Jdenticon value={projectName} size={12} />
              </div>
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {projectName}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {/* Heroicon name: solid/mail */}
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="truncate">
                      {numOfCampaigns + ' campaigns'}
                    </span>
                  </p>
                </div>
                <div className="hidden md:block">
                  <div>
                    <LastUpdate
                      isCurrentlyRunning={isCurrentlyRunning}
                      dateLastCampaign={dateLastCampaign}
                    />
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      {/* Heroicon name: solid/check-circle */}
                      <svg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Created on {new Date(dateCreated).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {/* Heroicon name: solid/chevron-right */}
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </span>
      </Link>
    </li>
  )
}
