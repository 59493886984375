import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { AdminContext } from '..'
import { AuthContext } from '../../../App'
import { Header } from './Header'
import { getMonthName } from '../../../utils'
import { getUserMonthsUsage } from '../../../services'
import { useContext } from 'react'

const sortMonths = (months) => {
  return months.sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year
    } else {
      return a.month - b.month
    }
  })
}

export const AdminPageUser = () => {
  const auth0 = useContext(AuthContext)
  const { users } = useContext(AdminContext)
  const [monthData, setMonthData] = useState([])
  const { userId } = useParams()
  const [userEmail, setUserEmail] = useState('')

  const handleGetMonthData = async () => {
    try {
      const months = await getUserMonthsUsage(userId, auth0)
      if (months.length) {
        setMonthData(sortMonths(months))
      } else {
        throw new Error('Months data not an array.')
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    handleGetMonthData()
  }, [])

  useEffect(() => {
    try {
      const email = users.filter((i) => i.user_id === userId)[0].email
      setUserEmail(email)
    } catch (e) {
      console.error('Unable to get user email, ', e)
    }
  }, [users])

  return (
    <>
      <Header userId={userId} userEmail={userEmail} />
      <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b sm:rounded-lg">
                <Month monthData={monthData} userId={userId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Month = ({ monthData, lightText = true, userId }) => {
  /**
   *  The month view shows the
   * total amount of CPU hours consumed,
   * the amount of fuzzing campaigns, and
   * the cumulative fuzzing duration for a month. It also shows
   * a list of fuzzing campaigns, showing for each at least the name,
   *                                        CPU hours and start date.
   */
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {monthData && monthData.length === 0 ? (
              <EmptyState />
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Month
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Hours Fuzzed
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {monthData.map((month, monthIdx) => (
                    <tr
                      key={'' + month.month + month.year}
                      className={monthIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {`${month.year} ${getMonthName(month.month)} `}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {month.hoursFuzzed}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/admin/${userId}/${month.year}/${month.month}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const EmptyState = () => {
  return (
    <div className=" bg-white rounded-md mt-8 px-8 py-4">
      <p className='text-center text-lg'>No campaigns</p>
      <p className='text-center text-base text-gray-500'>There are no recorded campaigns for this user.</p>
    </div>
  )
}
