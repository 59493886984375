import './index.scss'
import './prism.css'

import React, { Fragment } from 'react'

import { ColumnsHighlight } from './ColumnsHighlight'
import { IssueBlocks } from './IssueBlocks'

const FaasSourceCodeViewer = ({
  codeSample,
  visible = false,
  lineCoverage,
  ignoredIssuesDisplayed,
  filename = undefined,
}) => {
  let blocks = []
  let currentBlock = []
  let currentBlockStartingLine = 0
  let currentBlockCoveredLines = []
  let sampleSourceCode = []

  try {
    for (
      let currentLineNumber = 0;
      currentLineNumber < codeSample.length;
      currentLineNumber++
    ) {
      // if we find an issue, we close the block
      if (codeSample[currentLineNumber].isCovered) {
        currentBlockCoveredLines = [
          ...currentBlockCoveredLines,
          currentLineNumber + 1,
        ]
      }

      if (!codeSample[currentLineNumber].issues) {
        currentBlock = [...currentBlock, codeSample[currentLineNumber].line]
      }

      const nonIgnoredIssues =
        codeSample[currentLineNumber].issues &&
        codeSample[currentLineNumber].issues.filter((i) => !i.ignored)

      if (
        !ignoredIssuesDisplayed &&
        nonIgnoredIssues &&
        nonIgnoredIssues.length === 0
      ) {
        currentBlock = [...currentBlock, codeSample[currentLineNumber].line]
        continue
      }

      // when we find an issue, we close the block
      // we also check if it's the last line, since the last block also needs to be rendered
      if (
        codeSample[currentLineNumber].issues ||
        currentLineNumber === codeSample.length - 1
      ) {
        const issuesToDisplay = ignoredIssuesDisplayed
          ? codeSample[currentLineNumber].issues
          : nonIgnoredIssues

        // used to check if we entered the block due to an Issue or to it being the last line
        const hasIssues = issuesToDisplay && issuesToDisplay.length > 0
        const highlights = hasIssues
          ? issuesToDisplay.map(({ decodedLocation }) => {
              if (decodedLocation && decodedLocation.length >= 2) {
                return {
                  startingColumn: decodedLocation[0].column,
                  endingColumn: decodedLocation[1].column,
                  startingLine: decodedLocation[0].line,
                  endingLine: decodedLocation[1].line,
                }
              } else {
                return {
                  startingColumn: 0,
                  endingColumn: 0,
                  startingLine: 0,
                  endingLine: 0,
                }
              }
            })
          : []
        currentBlock = [...currentBlock, codeSample[currentLineNumber].line]
        if (currentLineNumber > 5) {
          sampleSourceCode = codeSample
            .slice(currentLineNumber - 4, currentLineNumber + 1)
            .map(({ line }) => line)
        } else {
          sampleSourceCode = codeSample
            .slice(0, currentLineNumber + 1)
            .map(({ line }) => line)
        }
        // @ts-ignore
        let Prism = window.Prism
        const highlightedCodeLines = currentBlock.map((line) => {
          return Prism.highlight(line, Prism.languages.solidity, 'sol')
        })
        const issueLine = currentBlock[currentBlock.length - 1]

        let currentCommentBlock = { start: -1, end: -1 }
        let commentBlocks = []
        for (let i = 0; i < currentBlock.length; i++) {
          if (currentBlock[i].indexOf('/*') > -1) {
            currentCommentBlock.start = i
          }
          if (currentBlock[i].indexOf('*/') > -1) {
            currentCommentBlock.end = i
            if (
              currentCommentBlock.start !== -1 &&
              currentCommentBlock.start !== i
            ) {
              commentBlocks.push(currentCommentBlock)
              currentCommentBlock = { start: -1, end: -1 }
            }
          }
        }

        let newCodeBlock = (
          <pre
            className="super-code line-numbers"
            data-start={currentBlockStartingLine}
            data-line={currentBlockCoveredLines.toString()}
          >
            {highlightedCodeLines
              // eslint-disable-next-line no-loop-func
              .map((highlightedLine, lineOffset) => {
                if (typeof highlightedLine === 'undefined') {
                  highlightedLine = ''
                }
                const isComment =
                  commentBlocks.filter(
                    ({ start, end }) => lineOffset >= start && lineOffset <= end
                  ).length > 0
                return (
                  <code
                    key={Math.floor(Math.random() * 100000000)}
                    className={`language-sol language-sol--${
                      highlightedLine.length < 1 && 'empty'
                    } ${isComment && 'block-comment'}`}
                    style={{ display: 'block', fontSize: '13px' }}
                    dangerouslySetInnerHTML={{ __html: highlightedLine }}
                    data-line-number={Math.floor(
                      currentBlockStartingLine + lineOffset + 1
                    )}
                    data-id={`line-${filename}-${Math.floor(
                      currentBlockStartingLine + lineOffset + 1
                    )}`}
                    data-line-covered={
                      lineCoverage &&
                      lineCoverage[
                        Math.floor(currentBlockStartingLine + lineOffset + 1)
                      ]
                    }
                  />
                )
              })}
            {highlights.map(
              (
                { startingColumn, endingColumn, startingLine, endingLine },
                j
              ) => {
                if (startingColumn > -1 && endingColumn > -1) {
                  return (
                    <ColumnsHighlight
                      issueLine={issueLine}
                      currentLine={currentLineNumber}
                      key={'' + issueLine + startingColumn + endingColumn + j}
                      startingColumn={startingColumn}
                      endingColumn={endingColumn}
                      startingLine={startingLine}
                      endingLine={endingLine}
                      index={j}
                    />
                  )
                }
                return <Fragment key={Math.floor(Math.random() * 1000)} />
              }
            )}
          </pre>
        )

        blocks = [
          ...blocks,
          <div key={'block_' + currentLineNumber + ''}>
            {newCodeBlock}
            {/* In case it's an issue block
        If not, it's just the last block of the code and there are no
        issues to render */}
            {hasIssues && (
              <IssueBlocks
                issues={issuesToDisplay}
                currentLineNumber={currentLineNumber}
              />
            )}
          </div>,
        ]
        // this is the line the next block will start at
        currentBlockStartingLine = currentLineNumber + 1
        // reset values
        currentBlock = []
        currentBlockCoveredLines = []
      }
    }
    return (
      <div
        key={'source-code-file__' + filename}
        data-testid="test-source-code-block"
        className={`mythx-source-code-viewer mythx-source-code-viewer--${
          visible && 'visible'
        }`}
      >
        {blocks}
      </div>
    )
  } catch (e) {
    console.error(e)
    return <p className='p-2'>There was an issue rendering this file.</p>
  }
}

export { FaasSourceCodeViewer }
