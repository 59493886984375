import { LightningBoltIcon, ShareIcon } from '@heroicons/react/solid'
import { useContext, useEffect, useRef, useState } from 'react'

import { AuthContext } from '../../App'
import { OwnerContext } from '.'
import { parseJwt } from '../../utils'

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, onClose) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export const Dropdown = ({
  show,
  setShowOptions,
  liveUpdatesStatus,
  toggleLiveUpdates,
  toggleSettings,
  campaignStatus,
  renderNewIssues,
  toggleCampaign,
  handleDownloadRawReport,
  proMode,
  toggleProMode,
  displayRuntimeExceptions,
  toggleDisplayRuntimeExceptions,
  shareReport,
  toggleShareReport,
}) => {
  const isOwner = useContext(OwnerContext)
  const auth0 = useContext(AuthContext)
  const wrapperRef = useRef(null)
  const [isAdmin, setIsAdmin] = useState(false)
  useOutsideAlerter(wrapperRef, setShowOptions)
  

  const checkAdmin = async () => {
    try {
      const accessToken = await auth0.getAccessTokenSilently()
      const { permissions } = parseJwt(accessToken)
      const isAdmin = permissions && permissions.indexOf('admin') > -1
      if (isAdmin) {
        setIsAdmin(true)
      }
    } catch (e) {
      setIsAdmin(false)
    }
  }

  useEffect(() => {
    checkAdmin()
  }, [])

  return (
    <div
      ref={show ? wrapperRef : undefined}
      className="relative inline-block text-left"
    >
      <div>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => {
            setShowOptions(!show)
          }}
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
          Options
          {/* Heroicon name: solid/chevron-down */}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {/*
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  */}
      <div
        className={`${
          !show ? 'hidden' : undefined
        } origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div className="py-1" role="none">
          {(isOwner && campaignStatus !== 'stopped') && (
            <span
              onClick={toggleCampaign}
              type="button"
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {campaignStatus === 'running' ? (
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                )}
              </svg>

              {campaignStatus === 'running'
                ? 'Stop Campaign'
                : 'Start Campaign'}
            </span>
          )}

          {isOwner ? (
            <span
              onClick={toggleSettings}
              type="button"
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
              </svg>
              {`Change Project`}
            </span>
          ) : undefined}
          {isOwner || isAdmin ? (
            <span
              onClick={handleDownloadRawReport}
              type="button"
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clipRule="evenodd"
                />
              </svg>
              {`Download Raw Data`}
            </span>
          ) : undefined}
          <span
            onClick={toggleProMode}
            type="button"
            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          >
            <LightningBoltIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
            {`${proMode ? 'Disable' : 'Enable'} Pro Mode`}
          </span>
          <span
            onClick={toggleDisplayRuntimeExceptions}
            type="button"
            className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          >
            <LightningBoltIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
            {`${displayRuntimeExceptions ? 'Hide' : 'Display'} Runtime Exceptions`}
          </span>
          {isOwner ? (
            <span
              onClick={toggleShareReport}
              type="button"
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
            >
              <ShareIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
              {`${shareReport ? 'Disable' : 'Enable'} Share`}
            </span>
          ) : undefined}
        </div>
      </div>
    </div>
  )
}
