import { TabsContext } from './AdminPage'
import { useContext } from 'react'

export const Header = () => {
  return (
    <header className="bg-gray-800 pb-32 pt-10 mb-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div className="flex min-w-0 container">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
            Admin Panel
          </h2>
          <div className="ml-8">
            <Tabs />
          </div>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4"></div>
      </div>
    </header>
  )
}



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Tabs = () => {
  const tabsContext = useContext(TabsContext)
  const { tabValue, setTab, TABS } = tabsContext

  const tabsList = [
    { name: 'Users', handleClick: () => setTab(TABS.USERS), current: tabValue == TABS.USERS },
    { name: 'Campaigns', handleClick: () => setTab(TABS.CAMPAIGNS), current: tabValue == TABS.CAMPAIGNS },
  ]

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabsList.find((tab) => tab.current).name}
        >
          {tabsList.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabsList.map((tab) => (
            <span
              key={tab.name}
              onClick={tab.handleClick}
              className={classNames(
                tab.current
                  ? 'bg-gray-100 text-gray-700'
                  : 'text-gray-400 hover:text-gray-200',
                'px-3 py-2 font-medium text-sm rounded-md pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </span>
          ))}
        </nav>
      </div>
    </div>
  )
}
