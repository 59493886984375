import { useEffect, useRef } from 'react'

export const Jdenticon = ({ value = 'test', size = '24' }) => {
  const icon = useRef(null)
  value = value.toLowerCase()
  useEffect(() => {
    if (typeof window.jdenticon !== 'undefined') {
      window.jdenticon.update(icon.current, value)
    }
  }, [value])

  return (
    <div>
      <svg
        className={`bg-white rounded-full shadow-inner h-${size} w-${size}`}
        data-jdenticon-value={value}
        ref={icon}
      />
    </div>
  )
}
