import { AuthContext } from '../App'
import { LoginIcon } from '@heroicons/react/solid'
import { useContext } from 'react'

export const LoginButton = ({ label = 'Login', size = 'normal', icon=true }) => {
  const auth0 = useContext(AuthContext)
  const { loginWithRedirect } = auth0

  const classes = size === 'normal' ? 'px-4 py-2 text-sm font-medium' : 'px-4 py-2 text-lg font-medium'

  return (
    <button
      onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
      id="login-button"
      type="button"
      className={`${classes} relative inline-flex items-center  border border-transparent shadow-sm  rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500`}
    >
      {icon ? <LoginIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> : undefined}
      <span>{label}</span>
    </button>
  )
}
