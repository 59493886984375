import { DangerConfirmationPopup } from '../../components/DangerConfirmationPopup'
import { useState } from 'react'

export const BillingPanel = ({
  activeSubscription,
  nextInvoice,
  nextInvoiceSummary
}) => {
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  
  const handleCancelation = async () => {
    try {
      alert('Subscription Cancelled!')
    } catch (e) {
      console.error('Error canceling plan')
    }
  }

  

  const getNextInvoiceDate = () => {
    if (nextInvoice?.[0]?.period?.end) {
      return new Date(nextInvoice[0].period.end * 1000).toDateString()
    }
    return ''
  }

  const getTotalHours = () => {
    if (nextInvoice?.[0]?.plan?.metadata?.includedMinutes) {
      // the quantity is in minutes and we want hours
      return Math.floor(nextInvoice?.[0]?.plan?.metadata?.includedMinutes/60)
    } else return 1
  }

  const getTotalUsedHours = () => {
    let sum = 0
    if (nextInvoice?.length) {
      for (let invoiceLine of nextInvoice) {
        sum += invoiceLine.quantity
      }
    }
    // the quantity is in minutes and we want hours
    return Math.floor(sum/60)
  }

  const getUsagePercentage = () => {
    let ratio = parseFloat(getTotalUsedHours() / getTotalHours())
    if (ratio >= 1) {
      return '100'
    } else {
      return '' + Math.floor(ratio * 100)
    }
  }

  const getOverageItems = () => {
    if (nextInvoice?.length) {
      const overageTiers = nextInvoice.filter(
        ({ amount, quantity }) => amount > 0 && quantity > 0
      )
      return overageTiers
    }
    return []
  }


  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <DangerConfirmationPopup
        display={displayConfirmation}
        title="Subscription Cancelation"
        mainText="Are you sure you want to cancel your subscription?"
        onConfirm={handleCancelation}
        onClose={() => setDisplayConfirmation(false)}
      />
      <div className=" border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Next billing date
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {getNextInvoiceDate()}
            </dd>
          </div>

          {/* Section that shows the plan usage percentage, currently hidden
          because we've stopped billing by scan and now have the unlimited policy.
          So there is no point showing how much of the unlimited you're using. */}
          {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Plan Usage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <progress id="file" max="100" value={getUsagePercentage()}>
                {' '}
                70%{' '}
              </progress>
              <span className="pb-2 block">
                {`${getTotalUsedHours()} of ${getTotalHours()} Fuzzing hours used`}
              </span>
              {getOverageItems().map((overageItem) => {
                return (
                  <span key={overageItem.id} className="pb-2 block">
                    {`Overage charges: $${Math.floor(
                      overageItem.amount / 100
                    )} (${overageItem.description})`}
                  </span>
                )
              })}
            </dd>
          </div> */}
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Next billing amount
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {`$ ${Math.floor(nextInvoiceSummary?.total / 100)}`}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
