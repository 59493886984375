import React, { Fragment } from 'react'

/**
 * This is the original properties table, now used only as a fallback when we fail
 * to decode properties.
 */

export const IssuesTable = ({
  properties,
  toggleExpandSourceContractCode,
}) => {
  const scrollToLine = (filename, line) => {
    toggleExpandSourceContractCode(filename, true)
    setTimeout(() => {
      const selector = `[data-id='line-${filename}-${line}']`
      const el = document.querySelector(selector)
      el && el.scrollIntoView({ behavior: 'smooth' })
    }, 50)
  }

  return (
    <div data-role="faas-report__properties-table" className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          data-role="faas-report__properties-table--inline-wrapper"
          className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
        >
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    File
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Checked
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Location
                  </th>
                </tr>
              </thead>
              <tbody>
                {properties.getIssuesToDisplay().map((property, i) => {
                  return (
                    <Fragment
                      key={
                        'issue' +
                        property.sourceCode.name +
                        property.getStartingLine() +
                        property.getStartingColumn() +
                        Math.random()
                      }
                    >
                      <Row
                        scrollToLine={scrollToLine}
                        even={(1 + i) % 2 === 0}
                        name={property.description.head}
                        file={property.sourceCode.name}
                        checked={true}
                        status={'fail'}
                        location={[
                          property.getStartingLine(),
                          property.getStartingColumn(),
                        ]}
                      />
                    </Fragment>
                  )
                })}
              </tbody>
              <tfoot className="border-none" />
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const Row = ({
  scrollToLine,
  even,
  name,
  file,
  checked,
  status,
  location,
}) => {
  const [line, column] = location

  return (
    <tr className={`${even ? 'bg-gray-50' : 'bg-white'}`}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {file.length && file.length >= 60 ? (
          <p dir="rtl" className="max-w-md overflow-x-auto hide-scrollbar">
            {file}
          </p>
        ) : (
          file
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        {checked ? (
          <svg
            className="block m-auto h-5 w-5 text-indigo-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            className="block m-auto h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex justify-center">
          <StatusBadge status={status} />
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <div className="flex justify-center">
          <button
            data-role="faas-report__properties-table-location-btn"
            onClick={() => {
              scrollToLine(file, line)
            }}
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {`L: ${line} C: ${column}`}
          </button>
        </div>
      </td>
    </tr>
  )
}

const StatusBadge = ({ status }) => {
  switch (status) {
    case 'pass':
      return (
        <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
          Passed
        </span>
      )
    case 'fail':
      return (
        <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800">
          Failed
        </span>
      )
    default:
      return (
        <span className="m-auto inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
          N/A
        </span>
      )
  }
}