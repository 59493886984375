import { Fragment, useContext, useEffect, useState } from 'react'

import { AuthContext } from '../../App'
import { BillingPanel } from './Billing'
import { ErrorPage } from '../../components/ErrorPage'
import { Header } from './Header'
import LoadingPage from '../../LoadingPage'
import { PricingCards } from './PricingCards'
import { SubscriptionPanel } from './Subscription'
import { UsagePanel } from './Usage'
import { getSubscriptions } from '../../services'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'

/* This example requires Tailwind CSS v2.0+ */
const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}


export const SubscriptionPage = () => {
  const { user } = useAuth0()
  const auth0 = useContext(AuthContext)
  const [activeSubscription, setActiveSubscription] = useState()
  const [nextInvoice, setNextInvoice] = useState()
  const [nextInvoiceSummary, setNextInvoiceSummary] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isLegacyPlan, setIsLegacyPlan] = useState(false)
  const [isFreePlan, setIsFreePlan] = useState(false)
  const [error, setError] = useState(false)

  const query = useQuery()

  const isFirstLogin = query.has('firstLogin')


  const handleGetSubscriptionData = async () => {
    try {
      const { activeSubscription, nextInvoiceSummary } = await getSubscriptions(
        auth0
      )
      if (nextInvoiceSummary) {
        setNextInvoice(nextInvoiceSummary.lines.data)
        setNextInvoiceSummary(nextInvoiceSummary)
      }
      if (activeSubscription) {
        setActiveSubscription(activeSubscription)
        if (
          activeSubscription?.plan?.metadata?.product
            .toLowerCase()
            .includes('legacy')
        ) {
          setIsLegacyPlan(true)
        }
        // check if the user is on a free plan
        // the metadata includes a key-value pair of "free_plan": "true"
        // "true" is a string, not a boolean
        if (activeSubscription?.metadata?.["free_plan"] === "true") {
          setIsFreePlan(true)
        }
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    handleGetSubscriptionData()
  }, [])

  if (error) {
    return <ErrorPage />
  }

  if (isLoading) {
    return <LoadingPage />
  } else
    return (
      <>
        <Header email={user?.email} />
        <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                {activeSubscription ? undefined : (
                  <div>
                    <div className="pb-5 "></div>
                    <PricingCards isFirstLogin={isFirstLogin} />
                  </div>
                )}
                {activeSubscription ? (
                  <>
                    {' '}
                    <div>
                      <div className="pb-5 ">
                        <h3 className="text-lg leading-6 font-medium text-white">
                          {'Subscription'}
                        </h3>
                      </div>
                      <SubscriptionPanel
                        activeSubscription={activeSubscription}
                      />
                    </div>
                    {(isLegacyPlan || !nextInvoice) ? undefined : (
                      <div>
                        <div className="mt-10  pb-5 ">
                          <h3 className="text-lg leading-6 font-medium text-black">
                            {'Billing'}
                          </h3>
                        </div>
                        <BillingPanel
                          nextInvoiceSummary={nextInvoiceSummary}
                          nextInvoice={nextInvoice}
                          activeSubscription={activeSubscription}
                        />
                        {isFreePlan ? <div>
                          <div className="mt-10  pb-5 ">
                            <h3 className="text-xl leading-6 font-medium text-black">
                              <span className='text-3xl'>🌟</span> Upgrade & Secure More with Our Paid Plans!
                            </h3>
                          </div>
                          <PricingCards isFirstLogin={isFirstLogin} upgradeMode={true} />
                        </div> : undefined}
                      </div>
                    )}
                    {/* We're hidding this section because we're currently using unlimited plans */}
                    {/* <div>
                      <div className="mt-10  pb-5 ">
                        <h3 className="text-lg leading-6 font-medium text-black">
                          {'Limits'}
                        </h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                          You can configure fuzzing hours limits to ensure you don't get charged for overage time.
                        </p>
                      </div>
                      <UsagePanel />
                    </div>{' '} */}
                  </>
                ) : undefined}
              </div>
            </div>
          </div>
        </div>
      </>
    )
}



