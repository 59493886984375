import { BarChart } from './BarChart'
import { CoverageChart } from "./CoverageChart"
import React from 'react'

export const AdvancedCharts = ({ charts }) => {
    const data = [
      {
        label: 'Instruction Coverage',
        component: (
          <CoverageChart
            height="18rem"
            data={charts.coverageChart}
            unit={charts.coverageChartUnit}
          />
        ),
      },
      {
        label: 'Branch Coverage',
        component: (
          <CoverageChart
            height="18rem"
            maxY="auto"
            seriesLabel="Coverage"
            yAxisLabel={`Amount of Branches Covered`}
            yIsPercentage={false}
            data={charts.branchCoverageChart}
            unit={charts.branchCoverageChartUnit}
          />
        ),
      },
      {
        label: 'Path Coverage',
        component: (
          <CoverageChart
            height="18rem"
            maxY="auto"
            seriesLabel="Coverage"
            yAxisLabel={`Amount of Paths Covered`}
            yIsPercentage={false}
            data={charts.pathCoverageChart}
            unit={charts.pathCoverageChartUnit}
          />
        ),
      },
      {
        label: 'Transaction Depth',
        component: (
          <BarChart height="18rem" data={charts.transactionDepthChart} />
        ),
      },
    ]
  
    return (
      <div className="mb-8 rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {data.map((action, actionIdx) => (
          <div
            key={action.label}
            className={classNames(
              actionIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === 3
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'relative group bg-white px-4 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:p-6'
            )}
          >
            <div></div>
            <div>
              <h3 className="text-base font-medium">{action.label}</h3>
              {action.component}
            </div>
          </div>
        ))}
      </div>
    )
  }
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  