import { Transition } from '@headlessui/react'

export const NewApiKeySlide = ({ show, onClose, onConfirm, apiKeyName="",  setApiKeyName}) => {
  const ignoreClick = (e) => {
    e.stopPropagation()
  }
  const onUpdate = (e) => setApiKeyName(e.target.value)
  return (
    <>
      <div
        onClick={onClose}
        className={`${!show && 'hidden'} fixed inset-0 overflow-hidden z-20`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <section
            className="absolute inset-y-0 pl-16 max-w-full right-0 flex"
            aria-labelledby="slide-over-heading"
          >
            <Transition
              show={show}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div onClick={ignoreClick} className="w-screen h-full max-w-xs z-10">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="pb-12 pt-14 px-4 bg-gray-50 sm:px-6">
                      <div className="flex items-center justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-medium text-black"
                        >
                          New API Key
                        </h2>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            onClick={onClose}
                            className="bg-gray-50 rounded-md text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
                          >
                            <span className="sr-only">Close panel</span>
                            {/* Heroicon name: outline/x */}
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new API Key.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              htmlFor="api_key_name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              API Key Name
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                value={apiKeyName}
                                onChange={onUpdate}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      onClick={onClose}
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={onConfirm}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </Transition>
          </section>
        </div>
      </div>
    </>
  )
}
