import { CheckIcon, XCircleIcon } from '@heroicons/react/solid'
import { Fragment, useContext, useState } from 'react'

import { AuthContext } from '../../App'
import { RadioGroup } from '@headlessui/react'
import { ShoppingCartIcon } from '@heroicons/react/solid'
import { createCheckoutSession } from '../../services'
import { useHistory } from 'react-router-dom'

const isProd = window.location.origin === 'https://fuzzing.diligence.tools'

let tiers = [
  {
    name: 'Explorer',
    href: '#',
    priceMonthly: 0,
    freePlan: true,
    description: 'For users who want to explore fuzzing as an additional security layer.',
    includedFeatures: [
      '100 campaigns included',
      'Max 5 minutes per campaign',
      '1x speed',
    ],

    stripePriceId: isProd
      ? 'price_1NYpEXEmopic2hb4Av8l2bMx'
      : 'price_1NYRUGEmopic2hb48ncCQLiF',
  },
  {
    name: 'Builder',
    href: '#',
    priceMonthly: 250,
    bestDeal: true,
    description: 'For users who are ready to level up their security practices and stress test longer.',
    includedFeatures: [
      'Unlimited campaigns',
      'Max 1 hour per campaign',
      '1x speed',
    ],
    jsxFeatures: [
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>🚀</span> Enjoy <b>unlimited campaigns.</b></span>,
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>⏲️</span> Dive deep with up to <b>1 hour per campaign.</b></span>,
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>🚅</span> Standard speed.</span>,
    ],
    stripePriceId: isProd
      ? 'price_1NYpEzEmopic2hb4AUJmhphV'
      : 'price_1NYRUkEmopic2hb42gqWyjpd',
  },
  {
    name: 'Builder Pro',
    href: '#',
    priceMonthly: 1999,
    description: 'For power users who require serious security,  in-depth analysis and no time constraints.',
    includedFeatures: [
      'Unlimited campaigns',
      'Unlimited duration',
      '2x speed',
    ],
    jsxFeatures: [
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>🚀</span> Enjoy <b>unlimited campaigns.</b></span>,
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>⏲️</span> <b>No time limit!</b> Test as long as you need.</span>,
      <span className="text-base sm:inline mr-2"><span className='text-2xl'>🚅</span> <b>2x</b> speed for faster, more efficient results.</span>,
    ],
    stripePriceId: isProd
      ? 'price_1NYpEuEmopic2hb4C4xwUDdM'
      : 'price_1NYRVMEmopic2hb4aHOpZsiQ',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const PricingCards = ({ isFirstLogin = false, upgradeMode = false }) => {
  const [selected, setSelected] = useState()
  const auth0 = useContext(AuthContext)
  const history = useHistory()

  const handleRefuseCheckout = () => {
    window.localStorage.setItem('refusedCheckout', true)
    history.push('/')
  }

  const redirectToCheckout = async () => {
    try {
      window.localStorage.setItem('refusedCheckout', true)
      const { url } = await createCheckoutSession(selected.stripePriceId, auth0)
      let newWin = window.open(url, '_blank')

      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        alert(
          'It seems your browser is blocking popups, please allow them on this page.'
        )
      }
    } catch (e) {
      console.error('There was an issue generating the checkout link. ', e)
      alert(
        'There was an issue generating the checkout link. Please contact support.'
      )
    }
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6">
      <>
        {upgradeMode ? <><h3 className="text-lg leading-6 font-medium text-gray-900">
          {/* {"Unlock limitless possibilities, enhanced speed, and deeper security insights with our Builder and Builder Pro plans, leaving the boundaries of the Explorer behind."} */}
        </h3>
          <div className="mt-2 mb-6 sm:flex sm:items-start sm:justify-between">
            <div className="text-md text-gray-500">
              <p>
                Unlock limitless possibilities, enhanced speed, and deeper security insights with our Builder and Builder Pro plans, leaving the boundaries of the Explorer behind.
              </p>
            </div>
          </div></> : <><h3 className="text-lg leading-6 font-medium text-gray-900">
            {"Select Subscription Plan"}
          </h3>
          <div className="mt-2 mb-6 sm:flex sm:items-start sm:justify-between">
            <div className="text-sm text-gray-500">
              <p>
                Unlock limitless possibilities, enhanced speed, and deeper security insights with our Builder and Builder Pro plans, leaving the boundaries of the Explorer behind.
              </p>
            </div>
          </div></>}
      </>
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-4">
          {tiers.map((plan) => {
            if (plan.freePlan && upgradeMode) return <></>
            return (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked, active }) =>
                  classNames(
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'ring-2 ring-indigo-500' : '',
                    'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className="text-lg leading-6 font-medium text-gray-600"
                        >
                          {plan.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="div"
                          className="text-gray-500"
                        >
                          <p className="text-sm text-gray-500 my-2">
                            {plan.description}
                          </p>
                          {(plan.jsxFeatures && upgradeMode) ? plan.jsxFeatures.map((feature) => (
                            <Fragment key={`${plan.name}_feature_${feature}`}>
                              <span className='mr-4'>{feature}</span>
                            </Fragment>
                          )) : plan.includedFeatures.map((feature) => (
                            <Fragment key={`${plan.name}_feature_${feature}`}>
                              <CheckIcon
                                className="flex-shrink-0 h-5 w-5 text-green-500 inline"
                                aria-hidden="true"
                              />
                              <span className="sm:inline mr-2">{feature}</span>
                            </Fragment>
                          ))}

                        </RadioGroup.Description>
                      </div>
                    </div>
                    <RadioGroup.Description
                      as="div"
                      className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
                    >
                      <div>
                        <span className="text-2xl font-extrabold text-gray-600">
                          {' '}
                          ${plan.priceMonthly}

                        </span>
                        <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                        {plan.bestDeal ? <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                          Amazing value!
                        </p> : undefined}
                      </div>
                    </RadioGroup.Description>
                    <div
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-500' : 'border-transparent',
                        'absolute -inset-px rounded-lg pointer-events-none'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            )
          })}

          {upgradeMode ? undefined : <RadioGroup.Option
            key={"enterprise"}
            value={"enterprise"}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-indigo-500' : '',
                'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
              )
            }
          >

            <>
              <div className="flex items-center">
                <div className="text-sm">
                  <RadioGroup.Label
                    as="p"
                    className="text-lg leading-6 font-medium text-gray-600"
                  >
                    Enterprise
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="div"
                    className="text-gray-500"
                  >
                    <p className="text-sm text-gray-500 my-2">
                      {"For larger teams needing multiple licenses, simultaneous campaigns and maximum performance."}
                    </p>
                    {[
                      'Unlimited campaigns',
                      'Unlimited duration',
                      '4x speed',
                    ].map((feature) => (
                      <Fragment key={`enterprise_feature_${feature}`}>
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500 inline"
                          aria-hidden="true"
                        />
                        <span className="sm:inline mr-2">{feature}</span>
                      </Fragment>
                    ))}
                  </RadioGroup.Description>
                </div>
              </div>
              <RadioGroup.Description
                as="div"
                className="mt-auto flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
              >
                <a href='https://share.hsforms.com/1TvCKjmAMTC2gCezqiexVTw2urwb' target='_blank' rel="noreferrer">
                  <button
                    type="button"
                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  ><span>
                      Contact Us
                      <svg style={{ display: "inline" }} className='inline' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </button>
                </a>
              </RadioGroup.Description>
              <div
                className={classNames(
                  'border-2',

                  'absolute -inset-px rounded-lg pointer-events-none'
                )}
                aria-hidden="true"
              />
            </>

          </RadioGroup.Option>}
        </div>
      </RadioGroup>
      <div className="mt-6 sm:flex-shrink-0 sm:flex sm:items-center justify-end">
        {isFirstLogin ? (
          <button
            onClick={handleRefuseCheckout}
            type="button"
            className={`mr-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
          >
            <XCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Maybe later
          </button>
        ) : undefined}
        <button
          onClick={redirectToCheckout}
          disabled={!selected}
          type="button"
          className={`${!selected && 'disabled'
            } inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          <ShoppingCartIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Checkout
        </button>
      </div>
    </div>
  )
}
