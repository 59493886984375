import { CheckIcon, ExclamationIcon, XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

export const AdvancedStatistics = ({ metadata, numberOfIssues }) => {
  const data = metadata
  return (
    <section>
      <div className="pb-5 my-8 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Advanced Statistics
        </h3>
      </div>
      <StatsCards data={data} numberOfIssues={numberOfIssues} />
    </section>
  )
}

const StatsCards = ({ data, numberOfIssues }) => {
  const totalInstructionCoverage = data?.TotalInstructionCoverage || 0
  const percentageInstructionCoverage = data?.TotalPercentageCoverage || 0
  const totalBranchCoverage = data?.TotalBranchCoverage || 0

  const { coveredPaths, transactionStatus, totalTestcasesExecuted } = data

  const totalTxs: any =
    typeof transactionStatus !== 'undefined'
      ? Object.values(transactionStatus).reduce(
          (a: number, b: number) => a + b,
          0
        )
      : 0

  // normalizing the tx status from absolute values into percentages.
  let transactionStatusPercentage = { outOfGas: 0, failing: 0, successful: 0 }
  if (typeof transactionStatus !== 'undefined') {
    for (let key of Object.keys(transactionStatus)) {
      transactionStatusPercentage[key] = Math.round(
        (transactionStatus[key] / totalTxs) * 100
      )
    }
  }

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 rounded-t-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <LineItemVariableUnit
          label="Instruction Coverage"
          variables={[
            { value: percentageInstructionCoverage, unit: '%' },
            { value: totalInstructionCoverage, unit: 'total' },
          ]}
        />

        <LineItem
          label="Branch Coverage"
          value={totalBranchCoverage}
          unit="branches"
        />
        <LineItem label="Path Coverage" value={coveredPaths || 0} unit="paths" />
      </dl>
      <dl className="grid grid-cols-1 border-t rounded-b-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <LineItemTxStatus
          label="Transaction Stats"
          success={`${transactionStatusPercentage.successful}%`}
          fail={`${transactionStatusPercentage.failing}%`}
          outOfGas={`${transactionStatusPercentage.outOfGas}%`}
        />
        <LineItem
          label="Issues Detected"
          value={numberOfIssues || 0}
          unit="issues"
        />
        <LineItem
          label="Total Test Cases"
          value={totalTestcasesExecuted || 0}
          unit="cases"
        />
      </dl>
    </div>
  )
}

const LineItem = ({ label, value, unit }) => {
  return (
    <div key={label} className="px-4 py-5 sm:p-6">
      <dt className="text-base font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          {value}
          <span className="ml-2 text-sm font-medium text-gray-500">{unit}</span>
        </div>
      </dd>
    </div>
  )
}

const LineItemVariableUnit = ({ label, variables }) => {
  const [variable, setVariable] = useState(variables[0])

  return (
    <div key={label} className="px-4 py-5 sm:p-6">
      <dt className="text-base font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          <span className="w-12">{variable.value}</span>
          {variables.map((v, i) => (
            <span
              key={`vars-${v}-${i}`}
              onClick={() => setVariable(v)}
              className={`ml-2 text-sm font-medium cursor-pointer ${
                v.unit === variable.unit
                  ? 'text-gray-800 font-bold'
                  : 'text-gray-500'
              }`}
            >
              {v.unit}
            </span>
          ))}
        </div>
      </dd>
    </div>
  )
}

const LineItemTxStatus = ({ label, success, fail, outOfGas }) => {
  return (
    <div key={label} className="px-4 py-5 sm:p-6">
      <dt className="text-base font-medium text-gray-900">{label}</dt>
      <dd className="mt-3 flex space-x-3 items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-green-600">
          <div className="has-tooltip cursor-default">
            <span className="border-b-2 border-green-600 tooltip rounded shadow-lg p-2 bg-white -mt-8 text-sm font-medium text-gray-700">
              Percentage of successful transactions.
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800">
              <CheckIcon
                className="mr-1 h-5 w-5 text-green-400"
                aria-hidden="true"
              />
              {success}
            </span>
          </div>
        </div>
        <div className="flex items-baseline text-2xl font-semibold text-pink-600">
          <div className="has-tooltip cursor-default">
            <span className="border-b-2 border-pink-600 tooltip rounded shadow-lg p-2 bg-white -mt-8 text-sm font-medium text-gray-700">
              Percentage of failed transactions.
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-pink-100 text-pink-800">
              <XIcon
                className="mr-1 h-5 w-5 text-pink-400"
                aria-hidden="true"
              />
              {fail}
            </span>
          </div>
        </div>
        <div className="flex items-baseline text-2xl font-semibold text-yellow-600">
          <div className="has-tooltip cursor-default">
            <span className="border-b-2 border-yellow-600 tooltip rounded shadow-lg p-2 bg-white -mt-8 text-sm font-medium text-gray-700">
              Percentage of transactions which ran out of gas.
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-yellow-100 text-yellow-800">
              <ExclamationIcon
                className="mr-1 h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
              {outOfGas}
            </span>
          </div>
        </div>
      </dd>
    </div>
  )
}
