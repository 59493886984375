import React from 'react'
import { SearchCircleIcon } from '@heroicons/react/solid'

export const PropertiesTitleWithSearch = ({ StatusFiltersComponent, setPropertiesSearch = () => { }, propertiesSearch = '' }) => {
  return (
    <div data-role="faas-report__properties-table-title" className="my-8 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Properties
      </h3>
      <div className='flex'>
        <StatusFiltersComponent />
        <div className="mt-3 sm:ml-4 sm:mt-0">
          <label htmlFor="mobile-search-property" className="sr-only">
            Search
          </label>
          <label htmlFor="desktop-search-property" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="mobile-search-property"
                id="mobile-search-property"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:hidden"
                placeholder="Search"
                value={propertiesSearch}
                onChange={(e) => setPropertiesSearch(e.target.value)}
              />
              <input
                type="text"
                name="desktop-search-property"
                id="desktop-search-property"
                className="hidden w-full min-w-[20em] rounded-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:block"
                placeholder="Search properties"
                value={propertiesSearch}
                onChange={(e) => setPropertiesSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
