import React, { useState } from 'react'

import ReactJson from 'react-json-view'
import get from 'lodash.get'

export const DebugPanel = ({ testCases, blockId, debugInfo }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name)

  const onClick = (tabName) => {
    setActiveTab(tabName)
  }

  const onSelect = (e) => {
    setActiveTab(e.target.value)
  }

  const renderActiveTab = (testCases, blockId) => {
    if (activeTab === tabs[0].name) {
      return <TxSteps testCases={testCases} blockId={blockId} />
    }
    if (activeTab === tabs[1].name) {
      if (typeof debugInfo === 'object') {
        return (
          <>
            {Object.entries(debugInfo).map((item, i) => {
              const [index, value] = item
              return (
                <StackTrace
                  key={'stacktrace-tab-' + i}
                  stackTrace={value.stackTrace}
                />
              )
            })}
          </>
        )
      } else {
        return <StackTrace key={'stacktrace-tab-'} stackTrace={false} />
      }
    }
    if (activeTab === tabs[2].name) {
      if (typeof debugInfo === 'object') {
        return (
          <>
            {Object.entries(debugInfo).map((item, i) => {
              const [index, value] = item
              return (
                <VariablesValues
                  key={'variables-tab-' + i}
                  debugVariables={value.debugVariables}
                />
              )
            })}
          </>
        )
      } else {
        return (
          <VariablesValues key={'variables-tab-'} debugVariables={undefined} />
        )
      }
    }
  }

  return (
    <div className="pb-5 sm:pb-0">
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={activeTab}
            onChange={onSelect}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                onClick={() => onClick(tab.name)}
                className={classNames(
                  activeTab === tab.name
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={activeTab === tab.name ? 'page' : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      {renderActiveTab(testCases, blockId)}
    </div>
  )
}

const tabs = [
  { name: 'Transaction Steps', href: '#', current: true },
  { name: 'Stack Trace', href: '#', current: false },
  { name: 'Values', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const VariablesValues = ({ debugVariables }) => {
  if (debugVariables) {
    return (
      <div className="bg-gray-700 mt-4 px-4 py-4 shadow overflow-hidden sm:rounded-lg">
        <span className="text-white font-mono">
          <ReactJson
            src={debugVariables}
            displayDataTypes={false}
            theme="flat"
            style={{ background: 'rgb(55, 65, 81)' }}
          />
        </span>
      </div>
    )
  } else {
    return (
      <div className="bg-gray-700 mt-4 px-4 py-4 shadow overflow-hidden sm:rounded-lg">
        <span className="text-white text-sm font-mono">
          Variables values not available.
        </span>
      </div>
    )
  }
}

const stackTraceExample = {
  issueUUID: '7fa430af-3e8a-5d61-93cb-4a3de3eedae0',
  testCaseIndex: 0,
  stackTrace:
    '/Users/rita_k/gillab/unisender-smart/multisender_for_fuzzing/contracts/NBU.sol <unknown function>@20381(<unknown>)\n/Users/rita_k/gillab/unisender-smart/multisender_for_fuzzing/contracts/NBU.sol <unknown function>@19875(<unknown>)\n/Users/rita_k/gillab/unisender-smart/multisender_for_fuzzing/contracts/NBU.sol:175:38 NBU.increaseAllowance(0xaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa0, 12562278940762051307810576155187175887916951722219502085603572910545)',
}

const StackTrace = ({ stackTrace }) => {
  if (stackTrace && stackTrace.length > -1) {
    if (stackTrace.split('\n').length < 0) {
      return (
        <span className="mt-2 text-white text-sm font-mono">{stackTrace}</span>
      )
    } else if (stackTrace) {
      return (
        <div className="bg-gray-700 mt-4 px-4 py-4 shadow overflow-x-auto sm:rounded-lg">
          {stackTrace.split('\n').map((trace, i) => {
            let regex = /(\.sol:\d+:\d+)/ // splits the file name and location from the function call
            let result = trace.split(regex)
            return result.map((item, j) => {
              if (j === 0 && result[1]) {
                // the first if the filename and location
                return (
                  <p
                    key={trace + '_' + i + '_' + j}
                    className="mt-2 text-white text-sm font-mono"
                  >
                    {item + result[1]}
                  </p>
                )
              }
              if (j === 1) {
                // we already included the contents of item 1 in the <p> above
                return <></>
              } else {
                // these are the function calls
                return (
                  <p
                    key={trace + '_' + i + '_' + j}
                    className="ml-4 text-white text-sm font-mono"
                  >
                    {item}
                  </p>
                )
              }
            })
          })}
        </div>
      )
    }
  } else {
    return (
      <div className="bg-gray-700 mt-4 px-4 py-4 shadow overflow-hidden sm:rounded-lg">
        <span className="text-white text-sm font-mono">
          Stack trace not available.
        </span>
      </div>
    )
  }
}

const parseTraces = ({ testCases }) => {
  const parsedTestCases = testCases.map((testCase) => {
    const parsedSteps = get(testCase, 'steps', []).map((step) => {
      const hasDecodedInput = step.decodedInput
      const hasName = step.name !== 'unknown'
      const failedToParse = !hasDecodedInput && !hasName
      let humanReadableInstruction = 'Unable to decode'
      if (hasDecodedInput) {
        humanReadableInstruction = `${step.decodedInput}`
      } else if (hasName) {
        humanReadableInstruction = `${step.name}`
      }
      return {
        ...step,
        hasDecodedInput,
        hasName,
        failedToParse,
        humanReadableInstruction,
      }
    })
    return { initialState: testCase.initialState, steps: parsedSteps }
  })
  return parsedTestCases
}

const TxSteps = ({ testCases, blockId }) => {
  return (
    <div className="mt-4" key={'trace-block' + blockId}>
      {parseTraces({ testCases }).map((testCase, i) => {
        return (
          <div key={`trace-block'-${i}-${blockId}`}>
            {/* We only need to say the test case number if there is more than 1 test case */}
            {testCases.length > 1 ? (
              <p className="mb-4 text-sm font-medium text-indigo-600 truncate">{`Test Case ${
                i + 1
              }`}</p>
            ) : undefined}

            {testCase.steps.map((step, j) => (
              <TxStep
                key={'trace-block-' + blockId + 'item-' + i}
                number={j + 1}
                step={step}
                operationType="CallTX"
              />
            ))}
          </div>
        )
      })}
    </div>
  )
}

const TxStep = ({ number, step, operationType }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <div className="bg-white shadow overflow-x-auto mb-4 sm:rounded-lg">
      <div className="flex px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex-col sm:flex sm:justify-between">
          <div className="flex items-center justify-between">
            <div className="flex">
              <p className="text-sm font-medium text-indigo-600 truncate">
                {`Step ${number}: ${operationType}`}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className=" overflow-scroll">
              <p className="mt-2 flex items-center text-xs font-mono text-black sm:mt-0">
                {`${
                  step.failedToParse ? 'Unable to parse transaction' : 'Call TX'
                } `}
                {number === 0 &&
                step.humanReadableInstruction === 'Unable to decode'
                  ? 'Creation Tx'
                  : step.humanReadableInstruction}
              </p>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={toggleExpanded}
          className="m-auto inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {`${isExpanded ? 'Hide' : 'Show'} transaction details`}
        </button>
      </div>
      {isExpanded ? <TxDataTable txData={step} /> : undefined}
    </div>
  )
}

const TxDataTable = ({ txData }) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            Variable
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Value
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {Object.entries(txData).map((item, index) => {
          const [key, value] = item
          return (
            <tr
              key={'txData' + index + key}
              className={index % 2 === 0 ? undefined : 'bg-gray-50'}
            >
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs font-medium font-mono text-gray-900 sm:pl-6">
                {key}
              </td>
              <td className="whitespace-nowrap px-3 py-2 text-xs font-mono text-gray-500">
                {value}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
