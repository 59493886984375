import {
  LockClosedIcon,
  LockOpenIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import {
  auth0Config,
  deleteRefreshToken,
  getRefreshToken,
  getRefreshTokensList,
} from '../../services'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AuthContext } from '../../App'
import { ClipboardCopyIcon } from '@heroicons/react/solid'
import { DangerConfirmationPopup } from '../../components/DangerConfirmationPopup'
import { Header } from './Header'
import { NewApiKeySlide } from './NewApiKeySlide'
import { ToasterContent } from '../../components/ToasterContent'
import { getApiKeyPrefix } from '../../utils'
import { toaster } from '../..'

const getEncodedApiKey = (refreshToken) => {
  const prefix = getApiKeyPrefix({
    auth0ClientId: auth0Config.clientId,
    auth0Domain: auth0Config.domain,
  })
  return `${prefix}::${refreshToken}`
}

const EmptyRow = () => (
  <tr className="bg-gray-50">
    <td colSpan={5}>
      <div className="flex place-content-center px-4 py-4 sm:px-6 ">
        <h3 className="text-gray-500">Empty</h3>
      </div>
    </td>
  </tr>
)

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const handleCopyToClipboard = (apiKey) => {
  window.navigator.clipboard.writeText(apiKey)
  toaster.notify(
    <ToasterContent label={`Your API key was copied to clipboard.`} />,
    { duration: 1000 }
  )
}

export const ApiKeysPage = () => {
  const auth0 = useContext(AuthContext)
  const [displayNewApiKeySlider, setDisplayNewApiKeySlider] = useState(false)
  const [authCode, setAuthCode] = useState()
  const [apiKeyName, setApiKeyName] = useState()
  const [apiKeysList, setApiKeysList] = useState([])
  const [recentNewKey, setRecentNewKey] = useState(false)
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false)
  const [targetDeleteApiKey, setTargetDeleteApiKey] = useState()

  const query = useQuery()
  const history = useHistory()

  const fetchData = async () => {
    try {
      const apiKeys = await getRefreshTokensList(auth0)
      setApiKeysList(apiKeys)
    } catch (e) {
      console.error(e)
    }
  }

  const cleanQueryParamCode = () => {
    if (query.has('code')) {
      query.delete('code')
      history.replace({
        search: query.toString(),
      })
    }
  }

  useEffect(() => {
    if (query.get('code')) {
      const code = query.get('code')
      setAuthCode(code)
      setDisplayNewApiKeySlider(true)
      cleanQueryParamCode()
    }
    fetchData()
  }, [])

  const handleDeleteApiKeyConfirmation = async () => {
      try {
        await deleteRefreshToken(targetDeleteApiKey, auth0)
        toaster.notify(<ToasterContent label={`Your API key was deleted.`} />, {
          duration: 1000,
        })
        fetchData()
      } catch (e) {
        console.error('Unable to delete API key.')
        toaster.notify(
          <ToasterContent
            label={`There was an issue deleting your API key.`}
            error={true}
          />,
          { duration: 1000 }
        )
      } finally{
        setDisplayDeleteConfirmation(false)
      }
  }

  const handleDeleteApiKey = async (apiKeyId) => {
   setTargetDeleteApiKey(apiKeyId)
   setDisplayDeleteConfirmation(true)
  }

  const handleGetAPIKey = async ({ authorizationCode }) => {
    try {
      const {
        id,
        name,
        owner,
        className,
        refreshToken,
        createdAt,
        accessToken,
      } = await getRefreshToken({
        name: apiKeyName,
        authorizationCode,
        auth0,
      })

      setApiKeysList([
        {
          refreshToken: refreshToken,
          id,
          name,
          owner,
          className,
          createdAt,
          accessToken,
          decoded: true,
        },
        ...apiKeysList,
      ])
      setRecentNewKey(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
    <DangerConfirmationPopup
        display={displayDeleteConfirmation}
        title={"Delete API Key"}
        mainText={"Are you sure you want to delete this API key? This action can not be undone."}
        onConfirm={handleDeleteApiKeyConfirmation}
        onClose={()=>{setDisplayDeleteConfirmation(false)}}
    />
      <Header
        projectName={'API Keys'}
        onClick={() => {
          setDisplayNewApiKeySlider(true)
        }}
      />
      <NewApiKeySlide
        show={displayNewApiKeySlider && !recentNewKey}
        apiKeyName={apiKeyName}
        setApiKeyName={setApiKeyName}
        onClose={() => {
          setDisplayNewApiKeySlider(false)
        }}
        onConfirm={() => {
          handleGetAPIKey({ authorizationCode: authCode })
        }}
      />
      <div className="-mt-32 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Creation Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Key
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiKeysList.length === 0 ? (
                      <EmptyRow />
                    ) : (
                      apiKeysList.map((key, index) => {
                        return key.decoded ? (
                          <RowUnlockedApiKey
                            key={'key-' + key.id}
                            apiKey={getEncodedApiKey(key.refreshToken)}
                            name={key.name}
                            index={index}
                            createdAt={key.createdAt}
                            id={key.id}
                            handleDeleteApiKey={handleDeleteApiKey}
                          />
                        ) : (
                          <RowLockedApiKey
                            key={'key-' + key.id}
                            apiKey={getEncodedApiKey(key.refreshToken)}
                            name={key.name}
                            index={index}
                            createdAt={key.createdAt}
                            id={key.id}
                            handleDeleteApiKey={handleDeleteApiKey}
                          />
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const RowLockedApiKey = ({
  apiKey,
  name,
  index,
  createdAt,
  id,
  handleDeleteApiKey,
}) => {
  return (
    <tr key={apiKey} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex">
          <LockClosedIcon className="h-5 w-5 mr-2" /> {name}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {new Date(createdAt).toLocaleDateString()}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {apiKey.slice(0, 3) + apiKey.slice(apiKey.length - 8)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        {/* <button
          className={`text-indigo-600 hover:text-indigo-900 disabled:opacity-50`}
        >
          
        </button> */}
        <button
          type="button"
          onClick={() => handleDeleteApiKey(id)}
          className="ml-3 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <TrashIcon className="h-5 w-5 mr-1" />
          Revoke
        </button>
      </td>
    </tr>
  )
}

const RowUnlockedApiKey = ({
  apiKey,
  name,
  index,
  createdAt,
  id,
  handleDeleteApiKey,
}) => {
  return (
    <tr key={apiKey} className={'bg-green-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex">
          <LockOpenIcon className="h-5 w-5 mr-2" /> {name}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {new Date(createdAt).toLocaleDateString()}
      </td>
      <td className="px-6 py-4 break-all text-sm text-gray-500 flex">
        {apiKey}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <button
          onClick={() => handleCopyToClipboard(apiKey)}
          type="button"
          className="ml-3 inline-flex items-center px-2 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ClipboardCopyIcon className="h-5 w-5 mr-1" />
          Copy
        </button>
        <button
          onClick={() => handleDeleteApiKey(id)}
          type="button"
          className="ml-3 inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <TrashIcon className="h-5 w-5 mr-1" />
          Revoke
        </button>
      </td>
    </tr>
  )
}
