import React from 'react'

export const ReportLoadingError = ({ groupId, error }) => (<div>
    <div className='card'>
      <div className='row'>
      <a onClick={()=> window.location.hash = `#/console/analyses/groups/${groupId}`} className='btn btn-simple pull-left button-go-back'><i className='fa pe-7s-back button-go-back' />Back to analyses list</a>
      </div>
      <div className='analyses-report-wrapper'>
        <div className=''>
          <div className='row header'>
            <div className='col-lg-9 col-md-9 col-sm-9'>
              {(error === 'Analysis failed') ?
                <h3>The analyses failed. Please try resubmitting. </h3>
                : <h3>There was an error generating the report.</h3>
              }
            </div>
          </div>
        </div>
      </div>
    </div>)
</div>)