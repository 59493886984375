import { Fragment, useEffect, useState } from 'react'

import ErrorModal from '../../components/ErrorModal'
import { Footer } from '../../components/LandingPage/components/Footer'
import { Header } from '../../components/LandingPage/components/Header'
import LoadingPage from '../../LoadingPage'
import { LoginButton } from '../../components/LoginButton'
import emailIllustration from '../../components/LandingPage/images/email-illustration.svg'
import screenshot from '../../components/LandingPage/images/screenshots/faas-report-1.png'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export default function StartPage() {
  const [errorMessages, setErrorMessages] = useState({
    title: '',
    description: '',
  })
  const [emailNeedsConfirmation, setEmailNeedsConfirmation] = useState(false)
  let query = useQuery()
  const { logout, loginWithRedirect } = useAuth0()

  // Redirect from Auth0 after failed login
  if (query.get('error')) {
    const error = query.get('error')
    const error_description = query.get('error_description')

    if (window) {
      localStorage.removeItem('faas-jwt')
    }

    if (!query.get('confirmEmail')) {
      const encodedErrorName = encodeURIComponent(error)
      const encodedErrorDescription = encodeURIComponent(error_description)

      logout({
        returnTo: `${window.location.origin}?confirmEmail=true&error=${encodedErrorName}&error_description=${encodedErrorDescription}`,
      })
    }
  }

  useEffect(() => {
    // redirect from Auth0 after logging out of a failed login
    // this allows us to display the error message
    const errorName = query.get('error')
    if (errorName) {
      const errorDescription = query.get('error_description')

      if (errorName === 'access_denied') {
        setEmailNeedsConfirmation(true)
      } else {
        setErrorMessages({
          title: errorName,
          description: errorDescription,
        })
      }
    } else {
      loginWithRedirect()
    }
  }, [])

  /**
   *
   * The current main content is only a loading page because
   * the main landing page is now https://consensys.net/diligence/fuzzing .
   * The loading screen will appear until 1 of 2 things happen:
   * 1. The user is redirected to the Auth0 login page
   * 2. The user is redirected to error login page, with the info to confirm their email
   */
  return (
    <div className="min-h-screen bg-gray-800">
      <div className="relative overflow-hidden">
        {errorMessages.title.length > 1 ? (
          <ErrorModal
            title={errorMessages.title}
            description={errorMessages.description}
          />
        ) : undefined}

        {emailNeedsConfirmation ? <VerifyEmailContent /> : <MainContent />}
      </div>
    </div>
  )
}

const MainContent = () => (
  <>
    <Header />
    <LoadingPage />
  </>
)

const VerifyEmailContent = () => {
  useEffect(() => {
    window.localStorage.setItem('firstLogin', 'true')
  }, [])

  return (
    <>
      <Header />
      <div className="h-full scroll-smooth bg-white antialiased [font-feature-settings:'ss01']">
        <div className="flex h-full flex-col">
          <main className="w-full">
            <ConfirmEmail />
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

const ConfirmEmail = () => {
  return (
    <div className="relative isolate overflow-hidden bg-white">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
        />
      </svg>
      <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-40 lg:px-8">
        <div className="text-center sm:text-left mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
          <h1 className=" mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            You've got mail!<br></br>
          </h1>
          <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Please confirm your email address.
          </h2>

          <div className="mt-10 flex  items-center gap-x-6 place-content-center sm:block">
            <LoginButton
              label="I've confirmed my email address"
              size="big"
              icon={false}
            />
          </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="-m-2  p-2 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                src={emailIllustration}
                alt="email"
                width={500}
                className=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
